import { apiGet, apiPut, getDeviceType } from '../../../utils';
import moment from 'moment';


export function getAllFaultySystemAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "status", "connection_type"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }

    return apiGet(`/api/nms/fault/get?filter=${JSON.stringify(arr)}`, null, null)

}

// export function getSingleHeatPumpDeviceAPI(id) {
export function getSingleFaultySystemAPI(id) {
    return apiGet(`/api/heat-pump/info/${id}`, null, 'safelink')
}

export function getFaultySystemAPIHistoryBySerialNoAPI(mac_address) {
    return apiGet(`/api/heat-pump/install-history?mac_address=${mac_address}`, null, 'safelink')
}
export function getFaultyHistoryAPI(device_id) {
    return apiGet(`/api/nms/fault/get-fault-history?device_id=${device_id}&device_type=${getDeviceType()}`)


}
export function updateFaultAPI(data) {
    return apiPut(`/api/nms/fault/update`, data,)
}
export function newFaultAPI(isUpdate) {
    if (isUpdate) {
        return apiPut(`/api/nms/fault/new-fault`,)
    }
    return apiGet(`/api/nms/fault/new-fault`,)
}
