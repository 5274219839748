import {
    getAllTariffsAPI,
    addTariffDetailAPI,
    distributorCompanyListAPI,
    getTarrifDetailAPI,
    updateTarrifDetailAPI,
    deleteTarrifAPI,
    tariffStructureListAPI,
    getDestributorByPostcodeAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_TARIFFS_REQUEST = 'GET_ALL_TARIFFS_REQUEST';
export const GET_ALL_TARIFFS_SUCCESS = 'GET_ALL_TARIFFS_SUCCESS';
export const GET_ALL_TARIFFS_FAILED = ' GET_ALL_TARIFFS_FAILED';

export const getAllTariffs = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_TARIFFS_REQUEST
        });
        getAllTariffsAPI(filters)
            .then((res) => {
                dispatch({
                    type: GET_ALL_TARIFFS_SUCCESS,
                    payload: res && res.info || {}
                });
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_TARIFFS_FAILED
                });
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err);
            });
    });

export const ADD_TARRIF_REQUEST = 'ADD_TARRIF_REQUEST';
export const ADD_TARRIF_SUCCESS = 'ADD_TARRIF_SUCCESS';
export const ADD_TARRIF_FAILED = 'ADD_TARRIF_FAILED';

export const addTariffDetail = data => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: ADD_TARRIF_REQUEST
        });
        addTariffDetailAPI(data)
            .then(res => {
                dispatch({
                    type: ADD_TARRIF_SUCCESS,
                    payload: res
                });
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res);
            })
            .catch(err => {
                dispatch({
                    type: ADD_TARRIF_FAILED
                });
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err);
            });
    });

export const GET_TARRIF_DETAIL_REQUEST = 'GET_TARRIF_DETAIL_REQUEST';
export const GET_TARRIF_DETAIL_SUCCESS = 'GET_TARRIF_DETAIL_SUCCESS';
export const GET_TARRIF_DETAIL_FAILED = 'GET_TARRIF_DETAIL_FAILED';

export const getTarrifDetail = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_TARRIF_DETAIL_REQUEST
        })
        getTarrifDetailAPI(id)
            .then(res => {
                dispatch({
                    type: GET_TARRIF_DETAIL_SUCCESS,
                    payload: res
                });
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_TARRIF_DETAIL_FAILED
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
    })



export const UPDATE_TARRIF_DETAIL_REQUEST = 'UPDATE_TARRIF_DETAIL_REQUEST';
export const UPDATE_TARRIF_DETAIL_SUCCESS = 'UPDATE_TARRIF_DETAIL_SUCCESS';
export const UPDATE_TARRIF_DETAIL_FAILED = 'UPDATE_TARRIF_DETAIL_FAILED';

export const updateTarrifDetail = (id, data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_TARRIF_DETAIL_REQUEST
        })
        updateTarrifDetailAPI(id, data)
            .then(res => {
                dispatch({
                    type: UPDATE_TARRIF_DETAIL_SUCCESS,
                    payload: res
                });
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_TARRIF_DETAIL_FAILED
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
    })


export const DELETE_TARRIF_REQUEST = 'DELETE_TARRIF_REQUEST';
export const DELETE_TARRIF_SUCCESS = 'DELETE_TARRIF_SUCCESS';
export const DELETE_TARRIF_FAILED = 'DELETE_TARRIF_FAILED';

export const deleteTarrif = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_TARRIF_REQUEST
        })
        deleteTarrifAPI(id)
            .then(res => {
                dispatch({
                    type: DELETE_TARRIF_SUCCESS,
                    payload: res
                });
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: DELETE_TARRIF_FAILED
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
    })


export const GET_TARIFF_STRUCTURE_LIST_REQUEST = 'GET_TARIFF_STRUCTURE_LIST_REQUEST';
export const GET_TARIFF_STRUCTURE_LIST_SUCCESS = 'GET_TARIFF_STRUCTURE_LIST_SUCCESS';
export const GET_TARIFF_STRUCTURE_LIST_FAILED = 'GET_TARIFF_STRUCTURE_LIST_FAILED';

export const tariffStructureList = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_TARIFF_STRUCTURE_LIST_REQUEST
        })
        tariffStructureListAPI(id)
            .then(res => {
                const { tariff_structure = [] } = res
                const tariffStructureList = tariff_structure?.length ? tariff_structure.map(val => ({ title: val.type, value: val.tariff_structure_id, tariffPlan: val.tariff_plan, isFlatRate: val.is_flat_rate })) : []
                dispatch({
                    type: GET_TARIFF_STRUCTURE_LIST_SUCCESS,
                    payload: tariffStructureList
                });
                return resolve(tariffStructureList)
            })
            .catch(err => {
                dispatch({
                    type: GET_TARIFF_STRUCTURE_LIST_FAILED
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
    })

export const GET_DISTRIBUTOR_COMPANY_LIST_REQUEST = 'GET_DISTRIBUTOR_COMPANY_LIST_REQUEST';
export const GET_DISTRIBUTOR_COMPANY_LIST_SUCCESS = 'GET_DISTRIBUTOR_COMPANY_LIST_SUCCESS';
export const GET_DISTRIBUTOR_COMPANY_LIST_FAILED = 'GET_DISTRIBUTOR_COMPANY_LIST_FAILED';

export const distributorCompanyList = () => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DISTRIBUTOR_COMPANY_LIST_REQUEST
        })
        distributorCompanyListAPI()
            .then((res) => {
                const { company_list = [] } = res || {}
                const distributorCompanyList = company_list?.length ? company_list.map(val => ({ title: val.company_name, value: val.id })) : []
                dispatch({
                    type: GET_DISTRIBUTOR_COMPANY_LIST_SUCCESS,
                    payload: distributorCompanyList
                });
                return resolve(company_list)
            })
            .catch(err => {
                dispatch({
                    type: GET_DISTRIBUTOR_COMPANY_LIST_FAILED
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
    })

export const GET_DISTRIBUTOR_ACCORDING_POSTCODE_REQUEST = 'GET_DISTRIBUTOR_ACCORDING_POSTCODE_REQUEST'
export const GET_DISTRIBUTOR_ACCORDING_POSTCODE_SUCCESS = 'GET_DISTRIBUTOR_ACCORDING_POSTCODE_SUCCESS'
export const GET_DISTRIBUTOR_ACCORDING_POSTCODE_FAILED = 'GET_DISTRIBUTOR_ACCORDING_POSTCODE_FAILED'

export const getDestributorByPostcode = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DISTRIBUTOR_ACCORDING_POSTCODE_REQUEST
        })
        getDestributorByPostcodeAPI(data)
            .then(res => {
                dispatch({
                    type: GET_DISTRIBUTOR_ACCORDING_POSTCODE_SUCCESS,
                    payload: res
                });
                // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_DISTRIBUTOR_ACCORDING_POSTCODE_FAILED,
                    payload: err
                })
                if (err?.response?.data?.message) {
                    dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                }
                return reject(err)
            })
    })


export const CLEAR_ALL_TARIFF_DATA = 'CLEAR_ALL_TARIFF_DATA';

export const clearStoreForAllTariff = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_TARIFF_DATA
        })
    }
}

