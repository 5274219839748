import { apiGet } from '../../../../utils';

export function getMultiSiteCompanyDetail(id) {
    return apiGet(`/api/nms/dashboard/msc/${id}/get`, null, 'heat_pump')
}

export function getallHeatPumpDevicesData (id) {
    return apiGet(`/api/nms/dashboard/msc/${id}/ws/devices/all?deviceType=heat_pump`, null, 'heat_pump')
}


export function getMultiSiteCompanySites(id, filters = {}) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/nms/dashboard/msc/${id}/sites?deviceType=heat_pump&filter=${JSON.stringify(arr)}`, null, 'heat_pump')
}

export function getMultiSiteCompanyCounts(id) {
    return apiGet(`/api/nms/dashboard/msc/${id}/counts?deviceType=heat_pump`, null, 'heat_pump')
}

