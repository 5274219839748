import *  as commonActions from "../../../common/actions";
import {
    getMultiSiteCompanyCounts,
    getMultiSiteCompanySites,
    getMultiSiteCompanyDetail,
    getallHeatPumpDevicesData,
} from './apis'
import { errorMessage } from "../../../../utils";


// multi site company Data
export const GET_MUTISITE_COMPANY_COUNT_REQUEST = 'GET_MUTISITE_COMPANY_COUNT_REQUEST';
export const GET_MUTISITE_COMPANY_COUNT_SUCCESS = 'GET_MUTISITE_COMPANY_COUNT_SUCCESS';
export const GET_MUTISITE_COMPANY_COUNT_FAIED = 'GET_MUTISITE_COMPANY_COUNT_FAIED';

export const GET_MUTISITE_COMPANY_SITES_REQUEST = 'GET_MUTISITE_COMPANY_SITES_REQUEST';
export const GET_MUTISITE_COMPANY_SITES_SUCCESS = 'GET_MUTISITE_COMPANY_SITES_SUCCESS';
export const GET_MUTISITE_COMPANY_SITES_FAIED = 'GET_MUTISITE_COMPANY_SITES_FAIED';

export const getmultiSiteDetails = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_MUTISITE_COMPANY_COUNT_REQUEST
        })
        const countPromises = getMultiSiteCompanyCounts(id);
        const sitesPromises = getMultiSiteCompanySites(id);
        const detailPromises = getMultiSiteCompanyDetail(id);
        const heatPumpDevices = getallHeatPumpDevicesData(id);
        Promise.all([countPromises, sitesPromises, detailPromises, heatPumpDevices]).then(values => {
            dispatch({
                type: GET_MUTISITE_COMPANY_COUNT_SUCCESS,
                payload: {
                    mscCount: values[0].info,
                    mscSites: values[1].info,
                    mscDetails: values[2].info,
                    heatPumpDevices: values[3].info,
                }
            })
            return resolve({values})
        }).catch(err => {
            dispatch({
                type: GET_MUTISITE_COMPANY_COUNT_FAIED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


    export const filterSites = (id, filters) => dispatch =>

        new Promise((resolve, reject) => {
            dispatch({
                type: GET_MUTISITE_COMPANY_SITES_REQUEST
            })
            getMultiSiteCompanySites(id, filters).then(res => {
                dispatch({
                    type: GET_MUTISITE_COMPANY_SITES_SUCCESS,
                    payload: res.info,
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: GET_MUTISITE_COMPANY_SITES_FAIED
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })

    


