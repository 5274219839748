import React from 'react'
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Button, CircularProgress } from '@material-ui/core'

function CsvDownload(props) {
    const { onClickDownloadButton, title = "Click here to download bulk upload sheet", iconlink = '', isLoading = false } = props
    return (
        <>
            {/* <div className="text-right ml-2 d-flex download-btn-date ">
                <div onClick={onClickDownloadButton}>
                    <LightTooltip title={title} >
                        {
                            !iconlink ? <a
                                className={`download-csv devices`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                             <img
                                    src="/images/download.svg"
                                    
                                />
                            </a> :
                                <img
                                    src={iconlink}
                                    className={`download-csv-imgicon devices`}
                                />
                        }
                    </LightTooltip>
                </div>
            </div> */}
            <Button
                className="button-btn btn-custom-primary mr-3"
                onClick={onClickDownloadButton}
            >
                {!isLoading ? "Download CSV" : <CircularProgress size={20} color='white' />}
            </Button>
        </>
    )
}

export default CsvDownload;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);
