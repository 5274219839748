import {
  getDashboardCountDataAPI,
  getDashboardCountDataNewAPI,
  getFaultNonFaultyDataAPI,
  getOnlineOfflineDataAPI,
} from "./apis";
import * as commonActions from "../../../../common/actions";
import { errorMessage } from "../../../../../utils";
// Graph Data
export const GET_AGENCY_GRAPH_DATA = "GET_AGENCY_GRAPH_DATA";
//
export const GET_AGENCY_DASHBOARD_COUNT_REQUEST = "GET_AGENCY_DASHBOARD_COUNT_REQUEST";
export const GET_AGENCY_DASHBOARD_COUNT_SUCCESS = "GET_AGENCY_DASHBOARD_COUNT_SUCCESS";
export const GET_AGENCY_DASHBOARD_COUNT_FAILED = "GET_AGENCY_DASHBOARD_COUNT_FAILED";
export const UPDATE_DASHBOARD_COUNT_SUCCESS = "UPDATE_DASHBOARD_COUNT_SUCCESS";

export const getDashboardCount = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_AGENCY_DASHBOARD_COUNT_REQUEST,
    });
    getDashboardCountDataAPI()
      .then((res) => {
        dispatch({
          type: GET_AGENCY_DASHBOARD_COUNT_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENCY_DASHBOARD_COUNT_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const updateDashboardCount = (data) => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_DASHBOARD_COUNT_SUCCESS,
      payload: data || {},
    });
  } catch (error) {
    dispatch({
      type: GET_AGENCY_DASHBOARD_COUNT_FAILED,
    });
  }
};

export const GET_AGENCY_DASHBOARD_COUNTDATA_REQUEST = "GET_AGENCY_DASHBOARD_COUNTDATA_REQUEST";
export const GET_AGENCY_DASHBOARD_COUNTDATA_SUCCESS = "GET_AGENCY_DASHBOARD_COUNTDATA_SUCCESS";
export const GET_AGENCY_DASHBOARD_COUNTDATA_FAILED = "GET_AGENCY_DASHBOARD_COUNTDATA_FAILED";
export const UPDATE_DASHBOARD_COUNTDATA_SUCCESS = "UPDATE_DASHBOARD_COUNTDATA_SUCCESS";

export const getDashboardCountData = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_AGENCY_DASHBOARD_COUNTDATA_REQUEST,
    });
    getDashboardCountDataNewAPI()
      .then((res) => {
        dispatch({
          type: GET_AGENCY_DASHBOARD_COUNTDATA_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENCY_DASHBOARD_COUNTDATA_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const updateDashboardCountData = (data) => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_DASHBOARD_COUNTDATA_SUCCESS,
      payload: data || {},
    });
  } catch (error) {
    dispatch({
      type: GET_AGENCY_DASHBOARD_COUNTDATA_FAILED,
    });
  }
};

export const GET_AGENCY_ONLINE_OFFLINE_REQUEST = "GET_AGENCY_ONLINE_OFFLINE_REQUEST";
export const GET_AGENCY_ONLINE_OFFLINE_SUCCESS = "GET_AGENCY_ONLINE_OFFLINE_SUCCESS";
export const GET_AGENCY_ONLINE_OFFLINE_FAILED = "GET_AGENCY_ONLINE_OFFLINE_FAILED";

export const getOnlineOfflineData = (year) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_AGENCY_ONLINE_OFFLINE_REQUEST,
    });
    getOnlineOfflineDataAPI(year)
      .then((res) => {
        dispatch({
          type: GET_AGENCY_ONLINE_OFFLINE_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENCY_ONLINE_OFFLINE_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const GET_AGENCY_FAULT_NON_FAULTY_REQUEST = "GET_AGENCY_FAULT_NON_FAULTY_REQUEST";
export const GET_AGENCY_FAULT_NON_FAULTY_SUCCESS = "GET_AGENCY_FAULT_NON_FAULTY_SUCCESS";
export const GET_AGENCY_FAULT_NON_FAULTY_FAILED = "GET_AGENCY_FAULT_NON_FAULTY_FAILED";

export const getFaultnonFaultyData = (year) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_AGENCY_FAULT_NON_FAULTY_REQUEST,
    });
    getFaultNonFaultyDataAPI(year)
      .then((res) => {
        dispatch({
          type: GET_AGENCY_FAULT_NON_FAULTY_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENCY_FAULT_NON_FAULTY_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
