import * as commonActions from "../../common/actions";

import { errorMessage } from "../../../utils";
import { getMultiSiteCompanies, postNewMultiSiteCompany, updateOldMultiSiteCompany } from "./apis";

export const GET_MSC_REQUEST = "GET_MSC_REQUEST";
export const GET_MSC_SUCCESS = "GET_MSC_SUCCESS";
export const GET_MSC_FAILED = "GET_MSC_FAILED";

export const ADD_NEW_MSC_REQUEST = "ADD_NEW_MSC_REQUEST";
export const ADD_NEW_MSC_SUCCESS = "ADD_NEW_MSC_SUCCESS";
export const ADD_NEW_MSC_FAIED = "ADD_NEW_MSC_FAIED";

export const getAllMultiSiteCompanies = (filters, userType) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_MSC_REQUEST,
    });
    getMultiSiteCompanies(filters, userType)
      .then((res) => {
        dispatch({
          type: GET_MSC_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_MSC_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const addNewMultiSiteCompany = (data, userType) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: ADD_NEW_MSC_REQUEST,
    });
    postNewMultiSiteCompany(data, userType)
      .then((res) => {
        dispatch({
          type: ADD_NEW_MSC_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: ADD_NEW_MSC_FAIED,
        });
        // dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
        return reject(err);
      });
  });

export const UPDATE_MSC_REQUEST = "UPDATE_MSC_REQUEST";
export const UPDATE_MSC_SUCCESS = "UPDATE_MSC_SUCCESS";
export const UPDATE_MSC_FAIED = "UPDATE_MSC_FAIED";

export const updateMultiSiteCompany = (data, userType) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_MSC_REQUEST,
    });
    updateOldMultiSiteCompany(data, userType)
      .then((res) => {
        dispatch({
          type: UPDATE_MSC_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_MSC_FAIED,
        });
        // dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
        return reject(err);
      });
  });
