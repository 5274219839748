import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    siteIsloading: false,
    mscCount: {},
    mscSites: {},
    mscDetails: {},
    heatPumpDevices: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_MUTISITE_COMPANY_COUNT_REQUEST :
            return { ...state, isLoading: true }

        case actionsTypes.GET_MUTISITE_COMPANY_COUNT_SUCCESS:
            return { ...state, isLoading: false, ...action.payload || {} }

        case actionsTypes.GET_MUTISITE_COMPANY_COUNT_FAIED:
            return { ...state, isLoading: false }
        
        case actionsTypes.GET_MUTISITE_COMPANY_SITES_REQUEST :
            return { ...state, siteIsloading: true }

        case actionsTypes.GET_MUTISITE_COMPANY_SITES_SUCCESS:
            return { ...state, siteIsloading: false, mscSites: action.payload || {} }

        case actionsTypes.GET_MUTISITE_COMPANY_SITES_FAIED:
            return { ...state, siteIsloading: false }

        default:
            return state
    }
}