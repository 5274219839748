import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allCustomers: {},
    customer: {},
    property: {},
    electricityRatesHistory: [],
    customerLoginPayload: [],
    cusAdminPropertiesForTable: {},
    cusAdminPropertiesIsLoading: false,
    custLoginPayloadForTable: {},
    cusLoginPayloadIsLoading: false,
    cusSharedPropertiesForTable: {},
    cusSharedPropertiesIsLoading: false,
    cusPropertyMembersForTable: {},
    cusPropertyMembersIsLoading: false,
    cusPropertyDevicesForTable: {},
    cusPropertyDevicesIsLoading: false,
    addCusPropertyMemeberIsLoading: false,
    customerEmailHistory: [],
    propertyTimelineHistory: [],
    trialHistory: {}
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_CUSTOMER_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_CUSTOMER_SUCCESS:
            return { ...state, isLoading: false, allCustomers: action.payload || {} }

        case actionsTypes.GET_ALL_CUSTOMER_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_CUSTOMER_DETAIL_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_CUSTOMER_DETAIL_SUCCESS:
            return { ...state, isLoading: false, customer: action.payload || {} }

        case actionsTypes.GET_CUSTOMER_DETAIL_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_CUSTOMER_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_CUSTOMER_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_CUSTOMER_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_PROPERTY_DETAIL_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_PROPERTY_DETAIL_SUCCESS:
            return { ...state, isLoading: false, property: action.payload || {} }

        case actionsTypes.GET_PROPERTY_DETAIL__FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_PROPERTY_DETAIL_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_PROPERTY_DETAIL_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_PROPERTY_DETAIL_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_CUSTOMER_DETAIL_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_CUSTOMER_DETAIL_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_CUSTOMER_DETAIL_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_ELECTRICITY_RATE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ELECTRICITY_RATE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, electricityRatesHistory: action.payload }

        case actionsTypes.GET_ELECTRICITY_RATE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_PROPERTY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_PROPERTY_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_PROPERTY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_REQUEST:
            return { ...state, cusAdminPropertiesIsLoading: true }

        case actionsTypes.GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS:
            return { ...state, cusAdminPropertiesIsLoading: false, cusAdminPropertiesForTable: action.payload || {} }

        case actionsTypes.GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_FAILED:
            return { ...state, cusAdminPropertiesIsLoading: false }

        case actionsTypes.GET_CUSTOMER_SHARED_PROPERTIES_FOR_TABLE_REQUEST:
            return { ...state, cusSharedPropertiesIsLoading: true }

        case actionsTypes.GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS:
            return { ...state, cusSharedPropertiesIsLoading: false, cusSharedPropertiesForTable: action.payload || {} }

        case actionsTypes.GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_FAILED:
            return { ...state, cusSharedPropertiesIsLoading: false }

        case actionsTypes.GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_REQUEST:
            return { ...state, cusLoginPayloadIsLoading: true }

        case actionsTypes.GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS:
            return { ...state, cusLoginPayloadIsLoading: false, custLoginPayloadForTable: action.payload || {} }

        case actionsTypes.GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_FAILED:
            return { ...state, cusLoginPayloadIsLoading: false }

        case actionsTypes.GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_REQUEST:
            return { ...state, cusPropertyMembersIsLoading: true }

        case actionsTypes.ADD_CUSTOMER_PROPERTY_MEMBERS__REQUEST:
            return { ...state, addCusPropertyMemeberIsLoading: true }

        case actionsTypes.ADD_CUSTOMER_PROPERTY_MEMBERS__SUCCESS:
            return { ...state, addCusPropertyMemeberIsLoading: false }

        case actionsTypes.ADD_CUSTOMER_PROPERTY_MEMBERS__FAILED:
            return { ...state, addCusPropertyMemeberIsLoading: false }
            
        case actionsTypes.GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_SUCCESS:
            return { ...state, cusPropertyMembersIsLoading: false, cusPropertyMembersForTable: action.payload || {} }

        case actionsTypes.GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_FAILED:
            return { ...state, cusPropertyMembersIsLoading: false }

        case actionsTypes.GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, cusPropertyDevicesIsLoading: true }

        case actionsTypes.GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, cusPropertyDevicesIsLoading: false, cusPropertyDevicesForTable: action.payload || {} }
        
        case actionsTypes.CLEAR_ALL_CUSTOMER_DATA_PROPERTY_DEVICE:
            return { ...state, cusPropertyDevicesIsLoading: false, cusPropertyDevicesForTable:  {} }

        case actionsTypes.GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_FAILED:
            return { ...state, cusPropertyDevicesIsLoading: false }

        case actionsTypes.GET_CUSTOMER_EMAIL_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_CUSTOMER_EMAIL_HISTORY_SUCCESS:
            return { ...state, isLoading: false, customerEmailHistory: action.payload || [] }

        case actionsTypes.GET_CUSTOMER_EMAIL_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_PROPERTY_TIMELINE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_PROPERTY_TIMELINE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, propertyTimelineHistory: action.payload || [] }

        case actionsTypes.GET_PROPERTY_TIMELINE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_TRIAL_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_TRIAL_HISTORY_SUCCESS:
            return { ...state, isLoading: false, trialHistory: action.payload || {} }

        case actionsTypes.GET_TRIAL_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_CUSTOMER_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }
}