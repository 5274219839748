import {
  getAllAgencyDevicesAPI,
  getAgencyDeviceDetailAPI,
  downloadCsvAPI,
  updateDeviceDetailAPI,
  getAgencyDeviceHistoryBySerialNoAPI,
  assignMultiSiteDevicesApi,
  getMultiSiteCompaniesAPI,
} from "./apis";
import * as commonActions from "../../common/actions";
import { errorMessage } from "../../../utils";

export const GET_ALL_AGENCY_DEVIECS_REQUEST = "GET_ALL_AGENCY_DEVIECS_REQUEST";
export const GET_ALL_AGENCY_DEVIECS_SUCCESS = "GET_ALL_AGENCY_DEVIECS_SUCCESS";
export const GET_ALL_AGENCY_DEVIECS_FAILED = "GET_ALL_AGENCY_DEVIECS_FAILED";

export const getAllAgencyDevices = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_ALL_AGENCY_DEVIECS_REQUEST,
    });
    getAllAgencyDevicesAPI(filters)
      .then((res) => {
        dispatch({
          type: GET_ALL_AGENCY_DEVIECS_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_AGENCY_DEVIECS_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const GET_AGENCY_DEVICE_DETAIL_REQUEST = "GET_AGENCY_DEVICE_DETAIL_REQUEST";
export const GET_AGENCY_DEVICE_DETAIL_SUCCESS = "GET_AGENCY_DEVICE_DETAIL_SUCCESS";
export const GET_AGENCY_DEVICE_DETAIL_FAILED = "GET_AGENCY_DEVICE_DETAIL_FAILED";

export const getAgencyDeviceDetail = (id) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_AGENCY_DEVICE_DETAIL_REQUEST,
    });
    getAgencyDeviceDetailAPI(id)
      .then((res) => {
        dispatch({
          type: GET_AGENCY_DEVICE_DETAIL_SUCCESS,
          payload: (res && res?.info) || {},
          // payload: {}
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENCY_DEVICE_DETAIL_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        reject(err);
      });
  });

export const DOWNLOAD_DEVICES_CSV_REQUEST = "DOWNLOAD_DEVICES_CSV_REQUEST";
export const DOWNLOAD_DEVICES_CSV_SUCCESS = "DOWNLOAD_DEVICES_CSV_SUCCESS";
export const DOWNLOAD_DEVICES_CSV_FAILED = "DOWNLOAD_DEVICES_CSV_FAILED";

export const downloadCsv = (ids, csvtype, allDevice) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: DOWNLOAD_DEVICES_CSV_REQUEST,
    });
    downloadCsvAPI(ids, csvtype, allDevice)
      .then((res) => {
        dispatch({
          type: DOWNLOAD_DEVICES_CSV_SUCCESS,
          payload: res && res.info,
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: DOWNLOAD_DEVICES_CSV_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        reject(err);
      });
  });

export const UPDATE_AGENCY_DEVICE_DETAIL_REQUEST = "UPDATE_AGENCY_DEVICE_DETAIL_REQUEST";
export const UPDATE_AGENCY_DEVICE_DETAIL_SUCCESS = "UPDATE_AGENCY_DEVICE_DETAIL_SUCCESS";
export const UPDATE_AGENCY_DEVICE_DETAIL_FAILED = "UPDATE_AGENCY_DEVICE_DETAIL_FAILED";

export const updateDeviceDetail = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: UPDATE_AGENCY_DEVICE_DETAIL_REQUEST,
    });
    updateDeviceDetailAPI(data)
      .then((res) => {
        const { info } = res;
        dispatch({
          type: UPDATE_AGENCY_DEVICE_DETAIL_SUCCESS,
          payload: info,
        });
        return resolve(info);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_AGENCY_DEVICE_DETAIL_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const GET_AGENCY_DEVICE_HISTORY_REQUEST = "GET_AGENCY_DEVICE_HISTORY_REQUEST";
export const GET_AGENCY_DEVICE_HISTORY_SUCCESS = "GET_AGENCY_DEVICE_HISTORY_SUCCESS";
export const GET_AGENCY_DEVICE_HISTORY_FAILED = "GET_AGENCY_DEVICE_HISTORY_FAILED";

export const getAgencyDeviceHistoryBySerialNo =
  (mac_address, type, name, id, date = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: GET_AGENCY_DEVICE_HISTORY_REQUEST,
      });
      getAgencyDeviceHistoryBySerialNoAPI(mac_address, type, name, id, date)
        .then((res) => {
          const { info = [] } = res || {};
          dispatch({
            type: GET_AGENCY_DEVICE_HISTORY_SUCCESS,
            payload: info,
          });
          // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
          return resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: GET_AGENCY_DEVICE_HISTORY_FAILED,
          });
          dispatch(
            commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
          );
          return reject(err);
        });
    });

export const CLEAR_ALL_DEVICE_DATA = "CLEAR_ALL_DEVICE_DATA";

export const clearStoreForAgencyDevice = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ALL_DEVICE_DATA,
    });
  };
};

export const GET_AGENCY_MSC_REQUEST = "GET_AGENCY_MSC_REQUEST";
export const GET_AGENCY_MSC_SUCCESS = "GET_AGENCY_MSC_SUCCESS";
export const GET_AGENCY_MSC_FAILED = "GET_AGENCY_MSC_FAILED";

export const getAllAgencyMultiSiteCompanies = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: GET_AGENCY_MSC_REQUEST,
    });
    getMultiSiteCompaniesAPI(filters)
      .then((res) => {
        dispatch({
          type: GET_AGENCY_MSC_SUCCESS,
          payload: (res && res.info) || {},
        });
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENCY_MSC_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });

export const ASSIGN_DEVICES_REQUEST = "ASSIGN_DEVICES_REQUEST";
export const ASSIGN_DEVICES_SUCCESS = "ASSIGN_DEVICES_SUCCESS";
export const ASSIGN_DEVICES_FAILED = "ASSIGN_DEVICES_FAILED";

export const assignMultiSiteDevices = (filters) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: ASSIGN_DEVICES_REQUEST,
    });
    assignMultiSiteDevicesApi(filters)
      .then((res) => {
        dispatch({
          type: ASSIGN_DEVICES_SUCCESS,
        });
        dispatch(commonActions.openSnackbarsState({ message: res && res.message }));
        return resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: ASSIGN_DEVICES_FAILED,
        });
        dispatch(
          commonActions.openSnackbarsState({ message: errorMessage(err), messageType: "error" })
        );
        return reject(err);
      });
  });
