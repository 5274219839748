import { apiGet, apiPost, apiPut, getDeviceType, isLoggedIn } from '../../../utils';
const { userType = '' } = isLoggedIn()

const generateUrlWithAdminType = (userType, url) => {
    return `/api${userType === 'Admin' ? '/' : `/${userType}/`}${url}`
}

export function getAllMultiSiteContactsAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        else if (["device_status", "status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`${generateUrlWithAdminType(userType, 'nms/contact/get/all')}?filter=${JSON.stringify(arr)}`)
}

// export function getMultiSiteCompanies(filters = []) {
//     let arr = []
//     for (const [key, value] of Object.entries(filters)) {
//         if (key === "isOpen") continue
//         if (typeof value !== "number" && !value?.length) continue

//         if (["status"].includes(key)) {
//             arr.push({ key, value, type: 'multi' })
//         } else {
//             arr.push({ key, value, type: "single" })
//         }
//     }
//     return apiGet(`/api/agency/nms/msc/get/all?filter=${JSON.stringify(arr)}`)
// }

export function assignMultiSiteDevicesApi(data) {
    return apiPost('/api/agency/nms/msc/assign-devices', data)
}

export function postNewMultiSiteContact(data) {
    return apiPost('/api/mscc/nms/contact/add', data)
}


export function updateMultiSiteContact(data) {
    return apiPut(`/api/mscc/nms/contact/update/${data.id}`, data)
}