import React from 'react'
import { Accordion, AccordionSummary, FormControlLabel, AccordionDetails, Checkbox } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
export default function CustomAccordion(props) {

    const { isOpen = false, label = '', handleChange = () => { }, children = null,isRadioIcon=false } = props
    return (
            <Accordion expanded={isOpen} square={true} >
                <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                    className=''
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox icon={isRadioIcon?<RadioButtonUncheckedIcon/>:<CheckBoxOutlineBlankIcon/>}
                        checkedIcon={isRadioIcon?<RadioButtonCheckedIcon/>:<CheckBoxIcon/> } />}
                        label={label}
                        onChange={handleChange}
                        checked={isOpen}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
    )
}