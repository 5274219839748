import *  as commonActions from "../../../../common/actions";
import {
    getMscSiteCounts,
    getMscSiteProperties,
    getMscSiteDetail,
    getMscSiteHeatPumpDeviceData,
} from './apis'
import { errorMessage } from "../../../../../utils";


// multi site company Data
export const GET_MSC_SITE_REQUEST = 'GET_MSC_SITE_REQUEST';
export const GET_MSC_SITE_SUCCESS = 'GET_MSC_SITE_SUCCESS';
export const GET_MSC_SITE_FAIED = 'GET_MSC_SITE_FAIED';

export const getmultiSiteDetails = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_MSC_SITE_REQUEST
        })
        const countPromises = getMscSiteCounts(id);
        const sitesPromises = getMscSiteProperties(id);
        const detailPromises = getMscSiteDetail(id);
        const heatPumpDevices = getMscSiteHeatPumpDeviceData(id);
        Promise.all([countPromises, sitesPromises, detailPromises, heatPumpDevices]).then(values => {
            dispatch({
                type: GET_MSC_SITE_SUCCESS,
                payload: {
                    mscSiteCount: values[0].info,
                    mscSiteProperties: values[1].info,
                    mscSiteDetails: values[2].info,
                    heatPumpDevices: values[3].info
                }
            })
            return resolve({values})
        }).catch(err => {
            dispatch({
                type: GET_MSC_SITE_FAIED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const filterProperties = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_MSC_SITE_REQUEST
        })
        const countPromises = getMscSiteCounts(id);
        const sitesPromises = getMscSiteProperties(id);
        const detailPromises = getMscSiteDetail(id);
        Promise.all([countPromises, sitesPromises, detailPromises]).then(values => {
            dispatch({
                type: GET_MSC_SITE_SUCCESS,
                payload: {
                    mscSiteCount: values[0].info,
                    mscSiteProperties: values[1].info,
                    mscSiteDetails: values[2].info
                }
            })
            return resolve({values})
        }).catch(err => {
            dispatch({
                type: GET_MSC_SITE_FAIED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


