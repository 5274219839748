import Validator from 'is_js'
import { isEmpty } from "lodash";
import { passwordValidationRegex, scrollIntoView } from "../../utils";

export function login(data) {
    let errors = {};
    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const emailRegex = /^.+@.+\..+$/


    if (Validator.empty(data.email)) {
        errors.email = "Email is required";
    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Invalid email address"
    }

    if (!data.password) {
        if (Validator.empty(data.password)) {
            errors.password = "Password is required"
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}

export function signup(data) {
    let errors = {};
    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const emailRegex = /^.+@.+\..+$/
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/

    if (Validator.empty(data.companyName)) {
        errors.companyName = "Company Name is required";
    }

    if (Validator.empty(data.companyEmail)) {
        errors.companyEmail = "Email is required";
    }

    if (!Validator.empty(data.companyEmail) && !emailRegex.test(data.companyEmail)) {
        errors.companyEmail = "Entered Email address is invalid"
    }

    if (Validator.empty(data.ABN)) {
        errors.ABN = "ABN is required";
    }

    if (data && data.ABN && data.ABN.length !== 11) {
        errors.ABN = "Invalid ABN";
    }

    if (Validator.empty(data.phone)) {
        errors.phone = "Phone Number is required";
    }

    if (data.phone && (data.phone.length < 8)) {
        errors.phone = "Phone Number must be 8 to 13 characters.";
    }

    if (data.phone && (data.phone.length > 13)) {
        errors.phone = "Phone Number must be 8 to 13 characters.";
    }

    if (!data.password) {
        if (Validator.empty(data.password)) {
            errors.passwordText = "Password is required"
        }
    }

    // if (data.password && !passwordRegex.test(data.password)) {
    //     errors.password = "Password must be at least 8 characters and must have at least 1 uppercase letter, 1 lowercase letter and 1 numeric digit"
    // }
    if (data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
        }
    }

    if (!data.termCondition) {
        errors.termCondition = "Please accept our Terms and Conditions & Privacy Policy"
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}

export function forgotPassword(data) {
    let errors = {};
    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const emailRegex = /^.+@.+\..+$/

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";
    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}

export function resetPassword(data) {
    let errors = {}
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/

    if (Validator.empty(data.password)) {
        errors.passwordText = "Password is required";
    }

    if (Validator.empty(data.confirmPassword)) {
        errors.confirmPassword = "Confirm Password is required";
    }

    // if (data.password && !passwordRegex.test(data.password)) {
    //     errors.password = "Password must be at least 8 characters and must have at least 1 uppercase letter, 1 lowercase letter and 1 numeric digit";
    // }
    if (data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
        }
    }

    if ((data.password && data.confirmPassword) && (data.password !== data.confirmPassword)) {
        errors.confirmPassword = "Password doesnot match";
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}

export function changeProfile(data) {
    let errors = {};
    const emailRegex = /^.+@.+\..+$/
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/
    let isError = false;

    if (Validator.empty(data.agencyName)) {
        errors.agencyName = "Agency Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileAgencyName')
        }

    }

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";

        if (!isError) {
            isError = true;
            scrollIntoView('profileEmail')
        }

    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"

        if (!isError) {
            isError = true;
            scrollIntoView('profileEmail')
        }

    }

    if (!data?.postcode) {
        errors.postcode = "Postal code is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profilePostalCode')
        }

    }

    if (data?.postcode?.length && data?.postcode?.length < 3) {
        errors.postcode = "Valid Postal code is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profilePostalCode')
        }

    }

    if (Validator.empty(data.contactFirstName)) {
        errors.contactFirstName = "Contact First Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileContactFirstName')
        }

    }

    if (Validator.empty(data.contactLastName)) {
        errors.contactLastName = "Contact Last Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileContactLastName')
        }
    }

    if (Validator.empty(data.contactNo) || data.contactNo === undefined) {
        errors.contactNo = "Contact Number is required";
        if (!isError) {
            isError = true;
            scrollIntoView('profileContactNo')
        }

    }

    if (data.contactNo && (data.contactNo.length < 8)) {
        errors.contactNo = "Contact Number must be 8 to 13 characters.";
        if (!isError) {
            isError = true;
            scrollIntoView('profileContactNo')
        }

    }

    if (data.contactNo && (data.contactNo.length > 13)) {
        errors.contactNo = "Contact Number must be 8 to 13 characters.";
        if (!isError) {
            isError = true;
            scrollIntoView('profileContactNo')
        }

    }

    if (data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
            if (!isError) {
                isError = true;
                scrollIntoView('profilePassword')
            }
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}



export function changeMultiSiteProfile(data) {
    let errors = {};
    const emailRegex = /^.+@.+\..+$/
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/
    let isError = false;

    if (Validator.empty(data.first_name)) {
        errors.first_name = "First Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileFirstName')
        }

    }
    if (Validator.empty(data.last_name)) {
        errors.last_name = "Last Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileLastName')
        }
    }

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";

        if (!isError) {
            isError = true;
            scrollIntoView('profileEmail')
        }

    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"

        if (!isError) {
            isError = true;
            scrollIntoView('profileEmail')
        }

    }


    if (Validator.empty(data.contact_no) || data.contact_no === undefined) {
        errors.contact_no = "Contact Number is required";
        if (!isError) {
            isError = true;
            scrollIntoView('profileContactNo')
        }

    }

    if (data.contact_no && (data.contact_no.length < 8)) {
        errors.contact_no = "Contact Number must be 8 to 13 characters.";
        if (!isError) {
            isError = true;
            scrollIntoView('profileContactNo')
        }

    }

    if (data.contact_no && (data.contact_no.length > 13)) {
        errors.contact_no = "Contact Number must be 8 to 13 characters.";
        if (!isError) {
            isError = true;
            scrollIntoView('profileContactNo')
        }

    }

    if (data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
            if (!isError) {
                isError = true;
                scrollIntoView('profilePassword')
            }
        }
    }

    if (Validator.empty(data.company_address)) {
        errors.company_address = "Company Address is required"

        if (!isError) {
            isError = true;
            scrollIntoView('companyAddress')
        }
    }
    if (Validator.empty(data.company_name)) {
        errors.company_name = "Company Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileCompanyName')
        }
    }
    return {
        isValid: isEmpty(errors),
        errors
    };
}




export function changeSiteManagerProfile(data) {
    let errors = {};
    const emailRegex = /^.+@.+\..+$/
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/
    let isError = false;

    if (Validator.empty(data.first_name)) {
        errors.first_name = "First Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileFirstName')
        }

    }
    if (Validator.empty(data.last_name)) {
        errors.last_name = "Last Name is required"

        if (!isError) {
            isError = true;
            scrollIntoView('profileLastName')
        }
    }

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";

        if (!isError) {
            isError = true;
            scrollIntoView('profileEmail')
        }

    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"

        if (!isError) {
            isError = true;
            scrollIntoView('profileEmail')
        }

    }
   

    if (data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
            if (!isError) {
                isError = true;
                scrollIntoView('profilePassword')
            }
        }
    }
   
    return {
        isValid: isEmpty(errors),
        errors
    };
}