import { apiGet, apiPost, apiPut, apiDelete, getDeviceType } from '../../../utils';


export function getAllInstallationAgentsAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue
        if (["device_status", "status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/agency/agents?filter=${JSON.stringify(arr)}`)
}

export function getAgentDetailByIdAPI(id) {
    return apiGet(`/api/agency/agent/get/${id}`)
}

export function updateInstallationAgentAPI(data, agentID) {
    let finalData = {
        email: data.email,
        // agent_name: data.agentName,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.mobile,
        status: data.status,
    }

    if (data.password) {
        finalData = { ...finalData, password: data.password }
    }
    return apiPut(`/api/agency/agent/edit/${agentID}`, finalData)
}


export function deleteInstallationAgentAPI(id) {
    return apiDelete(`/api/agency/agent/${id}`)
}

export function addInstallationAgentAPI(data) {
    let finalData = {
        // agent_name: data.agentName,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password,
        phone: data.mobile,
        status: data.status,
        // agency_id: data.agencyId,
        // tarrif_id: data.tarrifId
    }
    return apiPost('/api/agency/agent/add', finalData)
}


export function getAgentDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agency/agent-devices?deviceType=${getDeviceType()}&agent_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}

// export function getAgentRatedByCustomerForTableAPI(filters) {
//     let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '', rating = '' } = filters || {}
//     let obj = {
//         installer_id: id,
//         rating
//     }
//     return apiGet(`/api/agency/agents/rating-customers?page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`, obj)
// }

