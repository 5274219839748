import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

export default function SearchGooglePlace({ value = {}, onChange = () => {} }) {
  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAP_API_KEY}
        debounce={1200}
        autocompletionRequest={{
          componentRestrictions: { country: "au" },
        }}
        selectProps={{
          placeholder: "Search address...",
          components: { IndicatorSeparator: () => null },
          // menuPortalTarget: document.body

          value,
          onChange: onChange,
        }}
      />
    </div>
  );
}
