import { apiGet, apiPost, apiPut, getDeviceType, getWifiSignalStrengthValue } from "../../utils";
import moment from "moment";

export function getAllSiteManagerDevicesAPI(filters) {
  let arr = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen" || key === "signal_strength") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (key === "installation_date") {
      if (value[0] == null) continue;
      arr.push({
        key,
        value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
        end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
        type: "single",
      });
    } else if (["device_status", "status", "device_operation_status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }

  if (filters.signal_strength) {
    const strength = getWifiSignalStrengthValue(filters.signal_strength?.value);
    arr.push({ ...strength, key: "signal_strength", type: "single" });
  }

  return apiGet(
    `/api/sm/nms/devices/all?deviceType=${getDeviceType()}&filter=${JSON.stringify(arr)}`
  );
}

export function getAllProperty(filters = []) {
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  return apiGet(
    `/api/sm/nms/property/list?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`
  );
}

export function assignSiteManagerDevicesApi(data) {
  return apiPost("/api/sm/nms/assign-devices", data);
}

export function deleteDeviceFromSiteApi(obj) {
  return apiPost(`/api/sm/nms/remove-devices`, obj);
}

export function GetSiteManagerProfileAPI() {
  return apiGet(`/api/sm/nms/profile/get`);
}
