import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    dashboardCount: {},
    onlineOfflineData: {},
    faultNonfaultyData: {},
    isOnlineOfflineLoading: {},
    isFaultNonfaultyLoading: {},
    // graph Data
    onlineofflineGraphdata: { netprofit: { title: "Net Profit", data: [44, 55, 57, 56, 61, 58, 63, 60, 66] }, revenue: { title: "Revenue", data: [76, 85, 101, 98, 87, 105, 91, 114, 94] } },
    faultnonfaultyGraphdata: { faultydevice: { title: "Faulty Device", data: [44, 55, 57, 56, 61, 58, 63, 60, 66] }, nonfaultydevice: { title: "Faulty Device", data: [76, 85, 101, 98, 87, 105, 91, 114, 94] } },
    graphisloading: false,

}


export default function (state = { ...initialState }, action) {

    switch (action.type) {

        case actionsTypes.GET_GRAPH_DATA:
            return { ...state, graphisloading: true }

        // dashboard data
        case actionsTypes.GET_DASHBOARD_COUNT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DASHBOARD_COUNT_SUCCESS:
            return { ...state, isLoading: false, dashboardCount: action.payload || {} }

        case actionsTypes.UPDATE_DASHBOARD_COUNT_SUCCESS:
            return { ...state, isLoading: false, dashboardCount: action.payload || {} }

        case actionsTypes.GET_DASHBOARD_COUNT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_ONLINE_OFFLINE_REQUEST:
            return { ...state, isOnlineOfflineLoading: true }

        case actionsTypes.GET_ONLINE_OFFLINE_SUCCESS:
            return { ...state, isOnlineOfflineLoading: false, onlineOfflineData: action.payload || {} }

        case actionsTypes.GET_ONLINE_OFFLINE_FAILED:
            return { ...state, isOnlineOfflineLoading: false }

        case actionsTypes.GET_FAULT_NON_FAULTY_REQUEST:
            return { ...state, isFaultNonfaultyLoading: true }

        case actionsTypes.GET_FAULT_NON_FAULTY_SUCCESS:
            return { ...state, isFaultNonfaultyLoading: false, faultNonfaultyData: action.payload || {} }

        case actionsTypes.GET_FAULT_NON_FAULTY_FAILED:
            return { ...state, isFaultNonfaultyLoading: false }


        default:
            return state
    }

}