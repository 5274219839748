import { isEmpty } from "lodash";
import { scrollIntoView } from "../../utils";

export function validateEditProperty(data) {
    let errors = {};
    let isError = false;

    const { tariffDetail = {}, registeredAddress = {} } = data

    if (!registeredAddress?.property_name) {
        errors.property_name = "Property Name is required";

        if (!isError) {
            isError = true;
            scrollIntoView('admin_property_name')
        }
    }

    if (tariffDetail?.discount_type === "value") {
        if (!tariffDetail?.isFlatRate) {
            for (let i = 0; i < tariffDetail?.tariffPlan?.length; i++) {
                if (tariffDetail?.tariffPlan[i]?.unit_charge < tariffDetail?.discountPercent) {
                    errors.discountPercent = "Discount value must be less than unit charge"

                    if (!isError) {
                        isError = true;
                        scrollIntoView('discountPercent')
                    }
                }
            }
        }
        if (tariffDetail?.isFlatRate) {
            if (tariffDetail?.costPerKwh < tariffDetail?.discountPercent) {
                errors.discountPercent = "Discount value must be less than unit charge"

                if (!isError) {
                    isError = true;
                    scrollIntoView('discountPercent')
                }
            }
        }
    }

    // if (!registeredAddress?.street) {
    //     errors.street = "Street is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('admin_street')
    //     }
    // }

    // if (!registeredAddress?.street_number) {
    //     errors.street_number = "Street Number is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('admin_street_number')
    //     }
    // }

    // if (!registeredAddress?.street_type?.value) {
    //     errors.street_type = "Street Type is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('admin_street_type')
    //     }
    // }

    // if (!registeredAddress?.city) {
    //     errors.city = "Town/Suburb is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('admin_city')
    //     }
    // }

    // if (!registeredAddress?.state?.value) {
    //     errors.state = "State is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('admin_state')
    //     }
    // }

    if (!registeredAddress?.postal_code) {
        errors.postal_code = "Postcode is required";

        if (!isError) {
            isError = true;
            scrollIntoView('admin_postal_code')
        }
    }

    if ((registeredAddress?.postal_code) && ((registeredAddress.postal_code.length < 3) || (registeredAddress.postal_code.length > 4))) {
        errors.postal_code = "Valid postal code is required"

        if (!isError) {
            isError = true;
            scrollIntoView('admin_postal_code')
        }
    }

    if (!tariffDetail?.selectedElectricityDistributor?.value) {
        errors.selectedElectricityDistributor = "Electricity Distributor is required"

        if (!isError) {
            isError = true;
            scrollIntoView('admin_selectedElectricityDistributor')
        }
    }

    if (!tariffDetail?.seletedElectricityRetailer?.value) {
        errors.seletedElectricityRetailer = "Electricity Retailer is required"

        if (!isError) {
            isError = true;
            scrollIntoView('admin_seletedElectricityRetailer')
        }
    }

    if (!tariffDetail?.selectedTarrifStructure?.value) {
        errors.selectedTarrifStructure = "Tariff Structure is required"

        if (!isError) {
            isError = true;
            scrollIntoView('admin_selectedTarrifStructure')
        }
    }

    if (!tariffDetail?.isFlatRate) {
        let tariffPlanError = []
        tariffDetail?.tariffPlan?.length && tariffDetail.tariffPlan.map((val, index) => {
            if (!val?.unit_charge?.toString()) {
                tariffPlanError[index] = "Cost is required"
            }
        })
        if (tariffPlanError.length) {
            errors = { ...errors, tariffPlanError }
            if (!isError) {
                isError = true;
                scrollIntoView('admin_tariffPlanCost')
            }
        }
    }

    if (tariffDetail?.isFlatRate) {
        if (!tariffDetail?.costPerKwh.toString()) {
            errors.costPerKwh = "Cost is required"

            if (!isError) {
                isError = true;
                scrollIntoView('admin_cost_per_kwh')
            }
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}


export function validateEditCustomer(data) {
    let errors = {};
    const emailRegex = /^.+@.+\..+$/

    if (!data.email) {
        errors.email = "Email is required";
    }

    if (data.email && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"
    }

    if (!data?.first_name) {
        errors.first_name = "First Name is required";
    }

    if (!data?.last_name) {
        errors.last_name = "Last Name is required";
    }

    if (!data?.phone_no) {
        errors.phone_no = "Phone is required";
    }

    if (data?.phone_no && data?.phone_no.length > 13) {
        errors.phone_no = "Mobile Number must be 8 to 13 characters";
    }

    if (data?.phone_no && data?.phone_no.length < 8) {
        errors.phone_no = "Mobile Number must be 8 to 13 characters";
    }

    return {
        isValid: isEmpty(errors),
        errors
    };

}
