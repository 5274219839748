import React, { useEffect, useState, useCallback } from 'react';
import { Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputLabel, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Input from '../common/Input'
import * as actions from '../../containers/Agency/AgencyDevices/actions'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Validation from '../../containers/Validation';
import { openGlobalDialog } from '../../containers/common/actions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { isLoggedIn } from '../../utils';
import { getAllAgencies } from '../../containers/Admin/InstallerAgencies/actions';
import { addNewMultiSiteContact, getAllMultiSiteContacts } from '../../containers/MultiSite/MultiSiteContacts/actions';
const { userType = null } = isLoggedIn();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFilter = {
    limit: 25, serial_number: '', page: 1, sortBy: 'created_at', orderBy: 'desc',
}

const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    contact_no: 0
}

export default function AddMultisiteContact(props) {
    const dispatch = useDispatch()
    const [filters, setfilters] = useState({ ...initialFilter })
    const [data, setData] = useState(props?.globalModal?.data || { ...initialState })
    const [selectedDevice, setSelectedDevice] = useState([])
    const [agenciesList, setAgenciesList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [open, setOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { id = '' } = props?.globalModal?.data || {}
    const [errorMessage, setErrorMessage] = useState('');
    const isEdit = props?.globalModal?.data?.id ? true : false
    const closeModal = () => {
        setData({ ...initialState })
        props.closeModal();
    }

    const onChangeGroupField = ({ target: { value = '', type = 'text', name = '' } }) => {
        if ((name == 'first_name' || name == 'last_name') && value?.length > 60) return
        if (name === 'contact_no' && value?.length > 13) return;
        // if ((name == 'first_name' || name == 'last_name') && value && !/^[a-zA-Z]*$/.test(value)) {
        //     // setErrors({ ...errors, [name]: `${name == 'first_name' ? 'First Name' : 'last Name'} can only contain alphabets.` });
        //     return
        // }
        if (name == 'contact_no' && value && !/^[0-9]*$/.test(value)) {
            return
        }
        setData({ ...data, [name]: value });
        setErrors({ ...errors, [name]: '' });
    }


    const isValid = () => {
        let isValid = true;
        let error_msgs = {};
        if (!data.first_name) {
            isValid = false;
            error_msgs.first_name = 'First Name is required';
        } else {
            error_msgs.first_name = '';
        }

        if (!data.last_name) {
            isValid = false;
            error_msgs.last_name = 'Last Name is required';
        } else {
            error_msgs.last_name = '';
        }

        if (!data.email) {
            isValid = false;
            error_msgs.email = 'Email is required';
        } else if (!data.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            isValid = false;
            error_msgs.email = 'Please enter a valid email address';
        } else {
            error_msgs.email = '';
        }
        if (!data.contact_no) {
            isValid = false;
            error_msgs.contact_no = 'Contact Number is required';
        } else if (data.contact_no.length < 8 || !data.contact_no.match(/^[0-9]*$/)) {
            isValid = false;
            error_msgs.contact_no = 'Please enter a valid contact no.';
        } else {
            error_msgs.contact_no = '';
        }
        setErrors(error_msgs);
        return isValid;
    }

    const onSubmit = () => {
        setErrorMessage('');
        if (isValid()) {
            setIsLoading(true);
            dispatch(addNewMultiSiteContact(data, isEdit)).then(res => {
                dispatch(getAllMultiSiteContacts({}));
                closeModal();
            }).catch(err => {
                setIsLoading(false);
                setErrorMessage(err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something went wrong');
                console.log(err.message);
            })
        }
    }

    // let mergeOptionsArray = [...devices, ...selectedDevice]
    // let uniqueOptions = mergeOptionsArray.filter((elem, index) => mergeOptionsArray.findIndex(obj => obj.value === elem.value) === index);
    return (
        <>

            {/* <DialogTitle className="heat_pump_modal_title">
                <Grid item md={12} className="d-flex">
                    <h6>New Multi Site Contact</h6>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className='heat_pump_dialog_content'>
                <Grid item md={12} className="mb-3">
                    <InputLabel className="inputlabelHeatPumpModal">
                        First Name
                    </InputLabel>
                    <Input
                        variant="outlined"
                        fullWidth
                        name="first_name"
                        className="inputfieldHeatPumpModal"
                        label=""
                        type="text"
                        value={data.first_name || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.first_name && <span className="profile-error">{errors.first_name}</span>}
                </Grid>
                <Grid item md={12} className="mb-3">
                    <InputLabel className="inputlabelHeatPumpModal">
                        Last Name
                    </InputLabel>
                    <Input
                        variant="outlined"
                        fullWidth
                        name="last_name"
                        className="inputfieldHeatPumpModal"
                        label=""
                        type="text"
                        value={data.last_name || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.last_name && <span className="profile-error">{errors.last_name}</span>}
                </Grid>
                <Grid item md={12} className="mb-3">
                    <InputLabel className="inputlabelHeatPumpModal">
                        Email
                    </InputLabel>
                    <Input
                        variant="outlined"
                        className="inputfieldHeatPumpModal"
                        fullWidth
                        name="email"
                        label={``}
                        type="text"
                        value={data.email || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.email && <span className="profile-error">{errors.email}</span>}
                </Grid>
                <Grid item md={12} className="">
                    <InputLabel className="inputlabelHeatPumpModal">
                        Contact no.
                    </InputLabel>
                    <Input
                        variant="outlined"
                        fullWidth
                        name="contact_no"
                        className="inputfieldHeatPumpModal"
                        label=""
                        type="text"
                        value={data.contact_no || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.contact_no && <span className="profile-error">{errors.contact_no}</span>}
                </Grid>
                <Grid item md={12} className="mb-3">
                    {errorMessage && <span className="profile-error">{errorMessage}</span>}
                </Grid>
            </DialogContent> */}
            {/* <Grid> */}
            {/* <DialogActions className='heat_pump_dialog_action'> */}

            <DialogTitle className="heat_pump_modal_title">
                <Grid item md={12} className="d-flex">
                    <h6>{isEdit ? 'Update' : 'New'} Multi Site Contact</h6>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className='heat_pump_dialog_content'>
                <Grid item md={12} className="mb-3">
                    <InputLabel className="inputlabelHeatPumpModal">
                        First Name
                    </InputLabel>
                    <Input
                        variant="outlined"
                        fullWidth
                        name="first_name"
                        className="inputfieldHeatPumpModal"
                        label=""
                        type="text"
                        value={data.first_name || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.first_name && <span className="profile-error">{errors.first_name}</span>}
                </Grid>
                <Grid item md={12} className="mb-3">
                    <InputLabel className="inputlabelHeatPumpModal">
                        Last Name
                    </InputLabel>
                    <Input
                        variant="outlined"
                        fullWidth
                        name="last_name"
                        className="inputfieldHeatPumpModal"
                        label=""
                        type="text"
                        value={data.last_name || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.last_name && <span className="profile-error">{errors.last_name}</span>}
                </Grid>
                <Grid item md={12} className="mb-3">
                    <InputLabel className="inputlabelHeatPumpModal">
                        Contact Number
                    </InputLabel>
                    <Input
                        variant="outlined"
                        fullWidth
                        name="contact_no"
                        className="inputfieldHeatPumpModal"
                        label=""
                        type="text"
                        value={data.contact_no || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.contact_no && <span className="profile-error">{errors.contact_no}</span>}
                </Grid>
                <Grid item md={12} className="">
                    <InputLabel className="inputlabelHeatPumpModal">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Email
                            <InfoOutlinedIcon />
                        </div>
                    </InputLabel>
                    <Input
                        variant="outlined"
                        className="inputfieldHeatPumpModal"
                        fullWidth
                        name="email"
                        label={``}
                        type="text"
                        disabled={isEdit}
                        value={data.email || ""}
                        onChange={onChangeGroupField}
                    />
                    {errors.email && <span className="profile-error">{errors.email}</span>}
                </Grid>
                <Grid item md={12} className="mb-3">
                    {errorMessage && <span className="profile-error">{errorMessage}</span>}
                </Grid>
            </DialogContent>
            <Grid>
                <DialogActions className='heat_pump_dialog_action'>

                    <Button className={`cus-btn-modal-submit ${isLoading ? "disabled" : ""}`} onClick={isLoading ? null : onSubmit}>
                        {isLoading ? <CircularProgress size={18} color='white' /> : isEdit ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Grid>
        </>
    )
}

