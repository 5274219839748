import { apiGet, apiPost, apiPut, apiDelete, getWeekendsByWeekdays } from '../../../utils';
import moment from "moment";


export function getAllTariffsAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/tarrif/getCompanies?filter=${JSON.stringify(arr)}`)
}



export function addTariffDetailAPI(data) {
    let apiData = getTarrifDataInAPIFormat(data)
    return apiPost('/api/tarrif/create', apiData)
}

export function getTarrifDetailAPI(id) {
    return apiGet(`/api/tarrif/get/${id}`)
}

export function updateTarrifDetailAPI(id, data) {
    let apiData = getTarrifDataInAPIFormat(data)
    return apiPut(`/api/tarrif/edit/${id}`, apiData)
}

export function deleteTarrifAPI(id) {
    return apiDelete(`/api/tarrif/delete/${id}`)
}

export function tariffStructureListAPI(id) {
    return apiGet(`/api/tarrif/company/get-structure?company_id=${id}`)
}

export function distributorCompanyListAPI() {
    return apiGet('/api/tarrif/company/list')
}

export function getDestributorByPostcodeAPI(data) {
    const { postcode = '', id = '' } = data || {}
    return apiGet(`/api/tariff/get-companies?post_code=${postcode}&company_id=${id}`)
}


function getTarrifDataInAPIFormat(data) {
    let postCodeResult = data.postCode.map(function (item) {
        return item['value'];
    });
    postCodeResult = [...new Set(postCodeResult)]

    let finalData = data && data.typeTarrif && data.typeTarrif.map(({ type, flatRate, season: seasons, structureId }, typeTarrifIndex) => {
        if (!flatRate) {
            let season = seasons.map(({ weekdays, weekends, from, to, sameAsWeekendsToggle, season_id }, index) => {

                let updatedWeekends = weekends
                if (sameAsWeekendsToggle) {
                    updatedWeekends = getWeekendsByWeekdays(weekdays, weekends)
                }




                let { peak: dayPeak, offPeak: dayOffPeak, shoulder: dayShoulder, other: dayOther } = weekdays
                let { peak: endPeak, offPeak: endOffpeak, shoulder: endShouder, other: endOther } = updatedWeekends

                let dayPeak1 = dayPeak.length && dayPeak.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })
                let dayOffPeak1 = dayOffPeak.length && dayOffPeak.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })
                let dayShoulder1 = dayShoulder.length && dayShoulder.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })
                let dayOther1 = dayOther.length && dayOther.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })
                let endPeak1 = endPeak.length && endPeak.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })
                let endOffpeak1 = endOffpeak.length && endOffpeak.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })
                let endShouder1 = endShouder.length && endShouder.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })
                let endOther1 = endOther.length && endOther.map(({ startTime, endTime, name, weekDestributionId }, index) => {
                    return {
                        startTime: startTime && endTime ? moment(startTime).format('HH:mm') : '00:00:00',
                        endTime: startTime && endTime ? moment(endTime).format('HH:mm') : '00:00:00',
                        name,
                        weekDestributionId
                    }
                })

                return {
                    weekdays: { peak: dayPeak1, offPeak: dayOffPeak1, shoulder: dayShoulder1, other: dayOther1 },
                    weekends: { peak: endPeak1, offPeak: endOffpeak1, shoulder: endShouder1, other: endOther1 },
                    from: moment(parseInt(from)).format('YYYY-MM-DD') || '',
                    to: moment(parseInt(to)).format('YYYY-MM-DD') || '',
                    same_as_weekdays: sameAsWeekendsToggle ? 1 : 0,
                    season_id, season_id
                }

            })

            return {
                type,
                flatRate,
                season,
                structureId
            }

        }
        else return {
            type,
            flatRate,
            "season": [],
            structureId
        }

    })

    postCodeResult = postCodeResult.join(",")
    let apiData = {
        "company": data && data.company,
        "postCode": postCodeResult || '',
        "typeTarrif": finalData || []

    }
    return apiData
}