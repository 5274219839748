import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../containers/common/actions'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    CircularProgress,
    Grid,
    InputLabel
} from '@material-ui/core';
import Input from './Input';
import InputField from './InputField';
import { faultySystemValidation } from '../../containers/Validation/FaultySystem';
import { setDate } from 'rsuite/esm/utils/dateUtils';
import { getDeviceType } from '../../utils';
import deleteIcon from '../../assets/image/delete-modal-icon.svg';


function CustomDialogBox(props) {
    const dispatch = useDispatch();
    const { confirmModal } = useSelector(state => state.common)
    const [currentDeviceType, setCurrentDeviceType] = useState("");
    const { title = '', dialogtext = '', confirmAction = () => { }, description = '', faultydata = {} } = confirmModal || {}
    const [isLoading, setIsLoading] = useState(false)
    const [data, setdata] = useState({
        agent: "",
        job_comments: ""
    })
    const [errors, setErrors] = useState({})
    const onUpdateUserProfile = (e) => {
        const { name, value } = e.target
        setdata({ ...data, [name]: value })
    }

    const handleClose = () => {
        dispatch(actions.closeDeleteModal())
        setdata({})
        setErrors({})
    }

    const confirmedAction = () => {
        const { isValid, errors } = faultySystemValidation(data)
        setErrors({ ...errors })
        if (title == "Resolve Fault" && !isValid) {
            return
        }
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
        dispatch(actions.closeDeleteModal())
        confirmAction(data)
        setdata({})
        setErrors({})
    }



    useEffect(() => {
        let deviceType = getDeviceType();
        setCurrentDeviceType(deviceType);
        // Set the initial data state when faultydata changes
        if (confirmModal && confirmModal.title === "Resolve Fault" && faultydata) {
            setdata({
                agent: faultydata.agent || "",  // Set the default value or leave it empty
                job_comments: faultydata.job_comments || "",
            });
        }
        return () => {
            setCurrentDeviceType("");
        }
    }, [faultydata, confirmModal]);

    const { open, } = props;
    return (
        <Dialog
            open={open}
            maxWidth="xs"
            fullWidth={true}
            className="cus-dialog py-3"
        >
            <DialogTitle>
                {currentDeviceType == 'heat_pump' && title == "DELETE DEVICE" ? <img style={{ backgroundColor: '#FFECF0', borderRadius: '10px' }} className='p-1' src={deleteIcon} alt="delete-icon" /> : title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialogtext}
                    {description ? <br /> : null}
                    {description}

                    {title == "Resolve Fault" ?
                        <Grid container className='faulty_system_form'>
                            <Grid item md={12} sm={12} xs={12} className="input-group">
                                <InputLabel id="Agent" className="text-area-lable">Agent *</InputLabel>
                                <Input
                                    variant="outlined"
                                    fullWidth
                                    name="agent"
                                    // label="Agent *"
                                    type="text"
                                    value={data.agent}
                                    onChange={onUpdateUserProfile}
                                    error={errors.agent}
                                    id="agent"
                                />
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} className="input-group">
                                <InputLabel id={"job_comments"} className="text-area-lable">Job Comments</InputLabel>
                                <Input
                                    variant="outlined"
                                    fullWidth
                                    name="job_comments"
                                    // label="Job comments"
                                    type="textarea"
                                    className="input-text-area"
                                    value={data.job_comments}
                                    onChange={(e) => {
                                        const maxLength = 500;
                                        const regex = new RegExp(`^.{0,${maxLength}}$`);

                                        if (regex.test(e.target.value)) {
                                            onUpdateUserProfile(e);
                                        }
                                    }}
                                    error={errors.job_comments}
                                    id="job_comments"
                                />
                            </Grid>
                        </Grid> : null}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {currentDeviceType == 'heat_pump' && title == "DELETE DEVICE" ? (
                    <div className="dialog-btns">
                        <Button onClick={handleClose} className="button-btn cus-btn-modal-submit btn-custom-black mr-3" disabled={isLoading}>
                            No
                        </Button>

                        <Button onClick={confirmedAction} className="button-btn cus-btn-modal-submit btn-custom-primary" disabled={isLoading}>
                            {isLoading ? <CircularProgress size={18} color='white' /> : 'Yes'}
                        </Button>
                    </div>
                ) : (
                    <div className="dialog-btns">
                        <Button onClick={handleClose} className="button-btn btn-custom-black mr-3" disabled={isLoading}>
                            No
                        </Button>

                        {title == "Resolve Fault" ? <Button onClick={confirmedAction} className={faultydata.fault_status == 0 ? "button-btn btn-custom-primary" : "button-btn btn-custom-black mr-3"} disabled={isLoading || faultydata.fault_status == 1}>
                            {isLoading ? <CircularProgress size={18} color='white' /> : 'Yes'}
                        </Button> : <Button onClick={confirmedAction} className="button-btn btn-custom-primary" disabled={isLoading}>
                            {isLoading ? <CircularProgress size={18} color='white' /> : 'Yes'}
                        </Button>}
                    </div>
                )}

            </DialogActions>
        </Dialog>
    )
}

export default CustomDialogBox;