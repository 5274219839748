import React, { useState } from "react";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

const TableSearch = ({ searchValue, changehandler, placeholder = "Search..." }) => {


    return (
        <TextField id="outlined-basic" label="" placeholder={placeholder} variant="outlined" fullWidth InputProps={{
            startAdornment: (
                <InputAdornment position="start" className="srhIcon">
                    <SearchIcon />
                </InputAdornment>
            ),
        }}
            value={searchValue}
            onChange={(e) => changehandler(e.target.value)}
        />
    );
}

export default TableSearch;
