import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allAgents: {},
    agent: {},
    rating: {},
    agentDeviceInfo: {},
    agentDevicesForTable: {},
    agentDevicesIsLoading: false,
    agentLoginPayloadForTable: {},
    agentLoginPayloadIsLoading: false,
    // ratingCustomerList: {},
    // ratingCustomerListIsLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ADMIN_ALL_AGENTS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ADMIN_ALL_AGENTS_SUCCESS:
            return { ...state, isLoading: false, allAgents: action.payload || {} }

        case actionsTypes.GET_ADMIN_ALL_AGENTS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS:
            return { ...state, isLoading: false, agent: action.payload || {}, rating: action.rating || {}}

        case actionsTypes.GET_ADMIN_INSTALLER_AGENT_DETAIL_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_INSTALLER_AGENT_DETAIL_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_STORE_FOR_AGENT_DETAIL:
            return { ...state, ...initialState }

        case actionsTypes.GET_AGENT_DEVICE_INFO_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_AGENT_DEVICE_INFO_SUCCESS:
            return { ...state, isLoading: false, agentDeviceInfo: action.payload || {} }

        case actionsTypes.GET_AGENT_DEVICE_INFO_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_ADMIN_AGENT_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, agentDevicesIsLoading: true }

        case actionsTypes.GET_ADMIN_AGENT_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, agentDevicesIsLoading: false, agentDevicesForTable: action.payload || {} }

        case actionsTypes.GET_ADMIN_AGENT_DEVICES_FOR_TABLE_FAILED:
            return { ...state, agentDevicesIsLoading: false }

        case actionsTypes.GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_REQUEST:
            return { ...state, agentLoginPayloadIsLoading: true }

        case actionsTypes.GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS:
            return { ...state, agentLoginPayloadIsLoading: false, agentLoginPayloadForTable: action.payload || {} }

        case actionsTypes.GET_AGENT_LOGIN_PAYLOAD_FOR_TABLE_FAILED:
            return { ...state, agentLoginPayloadIsLoading: false }

        // case actionsTypes.GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_REQUEST:
        //     return { ...state, ratingCustomerListIsLoading: true }

        // case actionsTypes.GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_SUCCESS:
        //     return { ...state, ratingCustomerListIsLoading: false, ratingCustomerList: action.payload || {} }

        // case actionsTypes.GET_AGENT_RATING_BY_CUSTOMER_FOR_TABLE_FAILED:
        //     return { ...state, ratingCustomerListIsLoading: false }

        default:
            return state
    }
}