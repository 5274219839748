import *  as commonActions from "../../../../common/actions"
import { errorMessage } from '../../../../../utils'
import {
    getSingleHeatPumpDeviceAPI,
    getHeatPumpDeviceHistoryBySerialNoAPI,
    getAllHeatPumpDevicesAPI
} from './apis'
import { DownloadCSVAllDevicesAPI } from "../../../../common/apis";

export const GET_HEAT_PUMP_DEVICES_REQUEST = 'GET_HEAT_PUMP_DEVICES_REQUEST';
export const GET_HEAT_PUMP_DEVICES_SUCCESS = 'GET_HEAT_PUMP_DEVICES_SUCCESS';
export const GET_HEAT_PUMP_DEVICES_FAILED = 'GET_HEAT_PUMP_DEVICES_FAILED';

export const getallHeatPumpDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_HEAT_PUMP_DEVICES_REQUEST
        })
        getAllHeatPumpDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_HEAT_PUMP_DEVICES_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_HEAT_PUMP_DEVICES_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_SINGLE_HEAT_PUMP_REQUEST = 'GET_SINGLE_HEAT_PUMP_REQUEST';
export const GET_SINGLE_HEAT_PUMP_SUCCESS = 'GET_SINGLE_HEAT_PUMP_SUCCESS';
export const GET_SINGLE_HEAT_PUMP_FAILED = 'GET_SINGLE_HEAT_PUMP_FAILED';

export const getSingleHeatPumpDevice = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SINGLE_HEAT_PUMP_REQUEST
        })
        getSingleHeatPumpDeviceAPI(id).then(res => {
            dispatch({
                type: GET_SINGLE_HEAT_PUMP_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SINGLE_HEAT_PUMP_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST = 'GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST';
export const GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS = 'GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS';
export const GET_HEAT_PUMP_DEVICE_HISTORY_FAILED = 'GET_HEAT_PUMP_DEVICE_HISTORY_FAILED';

export const getHeatPumpDeviceHistoryBySerialNo = (serialNo) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST
        })
        getHeatPumpDeviceHistoryBySerialNoAPI(serialNo).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS,
                payload: info
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_HEAT_PUMP_DEVICE_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

    export const DownloadCSVAllDevices = () => dispatch => {
        return new Promise((resolve, reject) => {
            DownloadCSVAllDevicesAPI().then(res => {
                return resolve(res)
            }).catch(err => {
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }