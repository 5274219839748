import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { ArrowDropDown, RefreshRounded, Autorenew } from "@material-ui/icons";
import { useEffect, useState } from "react";
import * as commonActions from "../../../../common/actions";
import { useDispatch } from "react-redux";
import EnhancedTableHead from "../../../../../components/common/EnhancedTableHead";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { dateTimeWithoutGMT, dateWithTime, formatDateTime, isLoggedIn } from "../../../../../utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { webUrlByUserType } from "../../../../Agency/AgencyCustomers";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// const Customeheaders = (props) => {
//     const { headcell, order, orderBy, onRequestSort } = props
//     const [anchorEl, setAnchorEl] = useState(null);

//     const createSortHandler = (property) => (event) => {
//         onRequestSort(event, property);
//     };

//     return (
//         <TableHead>
//             <TableRow>
//                 <TableCell>
//                     {/* <Checkbox /> */}
//                 </TableCell>
//                 {headcell?.map((item, index) => (
//                     <TableCell key={index}>
//                         <div style={{ display: 'flex', alignItems: 'center', flexDirection: item.dropdown ? 'column' : 'row' }}>
//                             {item.label}
//                             <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <TableSortLabel
//                                     active={orderBy === item.id}
//                                     direction={orderBy === item.id ? order : 'asc'}
//                                     onClick={createSortHandler(item.id)}
//                                 />
//                                 {item.dropdown && <div style={{ marginLeft: 'auto' }}>
//                                     <Button
//                                         aria-controls={`filter-menu-${index}`}
//                                         aria-haspopup="true"
//                                         endIcon={<ArrowDropDown />}
//                                     >
//                                         All
//                                     </Button>
//                                     <Menu
//                                         id={`filter-menu-${index}`}
//                                         anchorEl={anchorEl}
//                                         keepMounted
//                                         open={Boolean(anchorEl)}
//                                     >
//                                         <MenuItem >All</MenuItem>
//                                         {/* <MenuItem onClick={() => handleMenuItemClick('Value1')}>Value1</MenuItem>
//                                         <MenuItem onClick={() => handleMenuItemClick('Value2')}>Value2</MenuItem> */}
//                                     </Menu>
//                                 </div>
//                                 }
//                             </div>
//                         </div>
//                     </TableCell>
//                 ))}
//             </TableRow>
//         </TableHead>
//     )
// }

const Customeheaders = (props) => {
  const { headcell, order, orderBy, onRequestSort } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>{/* <Checkbox /> */}</TableCell>
        {headcell?.map((item, index) => (
          <TableCell key={index}>
            <Typography style={{ whiteSpace: "nowrap" }}>
              {item.label}
              <TableSortLabel
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : "asc"}
                onClick={createSortHandler(item.id)}
              />
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const CustomeRows = (props) => {
  const { type, rows, order, orderBy, rowsPerPage, page, resolvedSelected } = props;
  const [selected, setSelected] = useState([]);
  const { userType = null } = isLoggedIn();
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const location = useLocation();

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const getRows = () => {
    if (type === "faultysystems") {
      return stableSort(rows, getComparator(order, orderBy));
    } else {
      return stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }
  };

  return (
    <TableBody>
      {getRows().map((item, index) => {
        const isItemSelected = isSelected(item.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        if (type === "Inventory") {
          return (
            <TableRow key={item.id}>
              <TableCell>
                {/* <Checkbox onChange={(event) => handleClick(event, item.id)} checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} /> */}
              </TableCell>
              <TableCell>{item.site_name}</TableCell>
              <TableCell>
                <Badge
                  badgeContent={item.status}
                  color={item.status === "Active" ? "primary" : "secondary"}
                />
              </TableCell>
              <TableCell>{item.devicenumber}</TableCell>
              <TableCell>{item.online}</TableCell>
              <TableCell>{item.offline}</TableCell>
              <TableCell>
                <Link href="#">{item.siteintegration}</Link>
              </TableCell>
              <TableCell>
                {/* {item.alertprofile.length !== 0 &&
                                        item.alertprofile.map((alertItem, index) => (
                                            <Chip key={index} size="small" label={alertItem} />
                                        ))} */}
              </TableCell>
            </TableRow>
          );
        }

        if (type === "devices") {
          return (
            <TableRow key={item.id}>
              <TableCell>
                <Checkbox
                  onChange={(event) => handleClick(event, item.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </TableCell>
              <TableCell>{item.serial_number}</TableCell>
              <TableCell>{item.mac_address}</TableCell>
              <TableCell>{item.soft_version}</TableCell>
              <TableCell>{item.wifi_name}</TableCell>
              <TableCell>
                {item.street_number}, {item.street}, {item.city}, {item.state}, {item.postal_code}
              </TableCell>
              <TableCell>{item.connection_type}</TableCell>
              <TableCell>{item.installation_date}</TableCell>
              <TableCell>{item.agency_name}</TableCell>
              <TableCell>{item.agent_name}</TableCell>
              <TableCell
                component="th"
                sx={{ color: item.status === "Completed" ? "green" : "red" }}>
                {item.status}
              </TableCell>

              <TableCell
                component="th"
                sx={{ color: item.device_status === "Acitve" ? "green" : "red" }}>
                {item.device_status}
              </TableCell>
              <TableCell>{item.id}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          );
        }

        if (type === "faultysystems") {
          let isOnlineCss = item?.heatpump?.is_online ? "green" : "red";
          let { heatpump } = item;
          let deviceOperationText =
            heatpump?.device_operation_status === 0
              ? "Off"
              : heatpump?.device_operation_status === 1
              ? "In Progress"
              : heatpump?.device_operation_status === 2
              ? "Completed"
              : "";
          let deviceOperationCSS =
            heatpump?.device_operation_status === 0
              ? "red"
              : heatpump?.device_operation_status === 1
              ? "yellow"
              : heatpump?.device_operation_status === 2
              ? "green"
              : "";
          return (
            <TableRow key={item.id}>
              <TableCell>{item?.heatpump?.serial_number || "-"}</TableCell>
              <TableCell>{item?.heatpump?.heat_pump_model?.model_name || "-"}</TableCell>
              <TableCell>
                {item.fault_code === "E96" &&
                  "E96 - Communication Failure Compressor Driver Board-Main Board (E96)"}
                {item.fault_code === "E98" &&
                  "E98 - Communication Failure Fan Driver Board-Main Board (E98)"}
                {item.fault_code !== "E98" && item.fault_code !== "E96"
                  ? `${item.fault_code || ""} - ${item.fault_description || ""}`
                  : ""}
              </TableCell>

              <TableCell>
                <span className={` badge ${isOnlineCss}`}>
                  {item?.heatpump?.is_online ? "Online" : "Offline" || "-"}
                </span>
              </TableCell>
              <TableCell>
                <span className={` badge ${deviceOperationCSS}`}>{deviceOperationText || "-"}</span>
              </TableCell>
              <TableCell>{item?.heatpump?.customer?.email || "-"}</TableCell>
              <TableCell>{formatDateTime(item?.history_datetime) || "-"}</TableCell>
              <TableCell>
                {item?.resolved || (
                  <span className={`resloved_text ${item?.fault_status === 1 ? "green" : "grey"}`}>
                    {item?.fault_status === 1 ? "Resolved" : "Unresolved"}
                  </span>
                )}
              </TableCell>
              <TableCell className="table-custom-width more">
                <Link
                  to={
                    userType === "Admin"
                      ? `/${userType.toLowerCase()}/heat_pump/device/view/${item?.device_id}?${
                          location?.search
                        }`
                      : userType === "Agency"
                      ? `/agency/heat_pump/device/view/${item?.device_id}?${location?.search}`
                      : `/${webUrlByUserType[userType.toLowerCase()]}/device/view/${
                          item?.device_history_id
                        }?${location?.search}`
                  }
                  className="link-tag">
                  <VisibilityIcon className="show-pointer" />
                </Link>
              </TableCell>
            </TableRow>
          );
        }
      })}
    </TableBody>
  );
};

const SiteTable = (props) => {
  const {
    headers,
    data,
    tableType,
    onPageChange,
    total,
    fillters,
    onChangeLimit,
    resolvedSelected,
    refresh,
  } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("device");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    onPageChange(event, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Paper>
        <TableContainer>
          <Table className="table-program" stickyHeader aria-label="sticky table" size="small">
            {/* <Customeheaders headcell={headers} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} /> */}
            <EnhancedTableHead headCells={headers} filters={fillters} />
            {data.length ? (
              <CustomeRows
                type={tableType}
                rows={data}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                page={page}
                resolvedSelected={resolvedSelected}
              />
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: "none" }} colSpan="10" className="table-no-data">
                    No Data Found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default SiteTable;
