import React, { useState } from 'react'
import { Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function CustomDrawer(props) {

    const { isLoading, className = ""} = props

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    return (
        <Drawer className={`${className} sidebar-right-side`} anchor={"right"} open={props.isOpen} onClose={props.onClose} transitionDuration={{ enter: 600, exit: 500 }}>
            <div className='header-sidebar-right'>
                <h2>Filters</h2>
                <CloseIcon onClick={props.onClose} />
            </div>
            {props.children}
        </Drawer>
    )
}