import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allContacts: {},
    isSubmitBtnloading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_MULTI_SITE_CONTACTS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_MULTI_SITE_CONTACTS_SUCCESS:
            return { ...state, isLoading: false, allContacts: action.payload || {} }

        case actionsTypes.GET_ALL_MULTI_SITE_CONTACTS_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}