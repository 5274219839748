import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import EnhancedTableHead from "../../../components/common/EnhancedTableHead";
import CustomTablePagination from "../../../components/common/CustomPagination";
import { withTranslation } from "react-i18next";
import {
  tablestyle,
  downloadFile,
  permissionType,
  trimFilters,
  handleTableCheckbox,
  propertyAddressFormat,
  getTextFormat,
  isLoggedIn,
} from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import _, { filter, rest } from "lodash";
import TableLoader from "../../../components/common/TableLoader";
import * as actions from "./actions";
import qs from "query-string";
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  IconButton,
  Typography,
} from "@material-ui/core";
import CusBackdrop from "../../../components/common/Backdrop";
import GetAppIcon from "@material-ui/icons/GetApp";
import CustomDrawer from "../../../components/common/CustomDrawer";
import CustomAccordion from "../../../components/common/CustomAccordion";
import CustomSearchBar from "../../../components/common/CustomSearchBar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchFilterButtons from "../../../components/common/SearchFilterButtons";
import FilterListIcon from "@material-ui/icons/FilterList";

// import TableSearch from "../Dashboards/common-comp-hp/TableSearch";
import HeatPumpTablePagination from "../../../components/common/HeatPumpTablePagination";
const { userType } = isLoggedIn();
const manageCustomersHead = [
  { id: "email", sortByKey: "email", label: "Email ID", className: "show-pointer" },
  { id: "first_name", sortByKey: "first_name", label: "Name", className: "show-pointer" },
  { id: "phone_no", sortByKey: "phone_no", label: "Phone", className: "show-pointer" },
  { id: "status", sortByKey: "status", label: "Status", className: "show-pointer" },
  { id: "feedback_form", sortByKey: "auto_sync", label: "Auto Sync", className: "show-pointer" },
  {
    id: "weekly_report",
    sortByKey: "weekly_report",
    label: "Weekly Report",
    className: "show-pointer",
  },
  { id: "action", sortByKey: "action", label: "Action", stickyClassName: "more" },
];

const manageCustomersLiveLinkHead = [
  { id: "email", sortByKey: "email", label: "Email ID", className: "show-pointer" },
  { id: "first_name", sortByKey: "first_name", label: "Name", className: "show-pointer" },
  { id: "phone_no", sortByKey: "phone_no", label: "Phone", className: "show-pointer" },
  // { id: 'location_permission_type', sortByKey: 'location_permission_type', label: "Location Permission", className: "show-pointer" },
  { id: "status", sortByKey: "status", label: "Status", className: "show-pointer" },
  { id: "action", sortByKey: "action", label: "Action", stickyClassName: "more" },
];

const manageCustomersHeatPump = [
  { id: "email", sortByKey: "email", label: "Email ID", className: "show-pointer" },
  { id: "full_name", sortByKey: "full_name", label: "Name", className: "show-pointer" },
  {
    id: "postal_code",
    sortByKey: "postal_code",
    label: "Street Address",
    className: "show-pointer",
  },
  { id: "phone_no", sortByKey: "phone_no", label: "Phone Number", className: "show-pointer" },
  {
    id: "address_type",
    sortByKey: "address_type",
    label: "Property Type",
    className: "show-pointer",
  },

  // { id: 'location_permission_type', sortByKey: 'location_permission_type', label: "Location Permission", className: "show-pointer" },
  { id: "status", sortByKey: "status", label: "Status", className: "show-pointer" },
  { id: "action", sortByKey: "action", label: "Action", stickyClassName: "more" },
];

const initialAccordion = {
  firstNameAcc: false,
  lastNameAcc: false,
  addressTypeAcc: false,
  emailAcc: false,
  nameAcc: false,
  phoneAcc: false,
  statusAcc: false,
};

const initialFilter = {
  isOpen: false,
  email: "",
  full_name: "",
  phone_no: "",
  address_type: "",
  status: [],
  limit: 25,
  page: 1,
  sortBy: "",
  orderBy: "desc",
};
export const webUrlByUserType = {
  mscc: "multisite",
  agency: "agency",
  sm: "sitemanager",
  admin: "admin",
};
function Customers(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [refresh, setRefresh] = useState(false);
  const [isDownloadingCsv, setDownloadingCsv] = useState(false);
  const [isTrialDownloadingCsv, setTrialDownloadingCsv] = useState(false);
  const [isDownloadAutoSyncCsv, setAutoSyncFeedbackCsv] = useState(false);
  const [openDownloadCSVModal, setOpenDownloadCSVModal] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  const [newFilters, setNewFilters] = useState({ ...initialFilter });
  const [accordion, setAccordian] = useState({ ...initialAccordion });
  const [clearFilterLoader, setClearFilterLoader] = useState(false);

  const { selectedSideBarProduct = "" } = useSelector((state) => state.common);
  const { allCustomers = {}, isLoading = false } = useSelector((state) => state.agencyCustomers);
  const { data: customers = [], total = 0 } = allCustomers;

  const [selected, setSelected] = useState([]);
  const delaySearch = useCallback(
    _.debounce((filters) => refreshCustomers(filters), 1000),
    []
  );
  const delayGoToPagination = useCallback(
    _.debounce((filters) => goToPageFun(filters), 1000),
    []
  );
  let deviceType = window.localStorage.getItem("selectedProduct");

  useEffect(() => {
    let tempFilter = {};
    if (location.search) {
      let {
        firstNameAcc,
        lastNameAcc,
        addressTypeAcc,
        phoneAcc,
        email,
        emailAcc,
        nameAcc,
        full_name,
        phone_no,
        address_type = "",
        status = [],
        statusAcc,
        isOpen,
        limit,
        page,
        sortBy,
        orderBy,
      } = { ...qs.parse(location.search, { arrayFormat: "bracket" }) };

      tempFilter = {
        isOpen: isOpen === "false" ? false : true,
        email,
        full_name,
        phone_no,
        status,
        address_type,
        limit: parseInt(limit),
        page: parseInt(page),
        sortBy,
        orderBy,
      };

      let tempAcc = {
        emailAcc: emailAcc === "false" ? false : true,
        nameAcc: nameAcc === "false" ? false : true,
        firstNameAcc: firstNameAcc === "false" ? false : true,
        lastNameAcc: lastNameAcc === "false" ? false : true,
        addressTypeAcc: addressTypeAcc === "false" ? false : true,
        phoneAcc: phoneAcc === "false" ? false : true,
        statusAcc: statusAcc === "false" ? false : true,
      };
      setNewFilters(tempFilter);
      setAccordian(tempAcc);
    }

    refreshCustomers(tempFilter);
    return () => {
      dispatch(actions.clearStoreForCutomer());
    };
  }, []);

  useEffect(() => {
    if (userType) {
      let tempFilter = { ...accordion, ...trimFilters(newFilters) };

      let { status = [], ...rest } = tempFilter;
      let stringRestFilters = qs.stringify({ ...rest });
      let statusInString = qs.stringify({ status: status }, { arrayFormat: "bracket" });
      location.search = statusInString + "&" + stringRestFilters;
      history.push({
        pathname: `/${webUrlByUserType[userType?.toLowerCase()]}/customers`,
        search: location.search,
      });
    }
  }, [accordion, newFilters, userType]);

  const refreshCustomers = (filters) => {
    dispatch(actions.getAllCustomers(filters));
  };

  const goToPageFun = (value) => {
    refreshCustomers(value);
  };

  const onClickRefresh = () => {
    setRefresh(true);
    dispatch(actions.getAllCustomers(newFilters))
      .then(() => setRefresh(false))
      .catch((err) => setRefresh(false));
  };

  const onPageChange = (e, page) => {
    refreshCustomers({ ...newFilters, page });
    setNewFilters({ ...newFilters, page });
  };

  const onChangeLimit = (e) => {
    refreshCustomers({
      ...newFilters,
      limit: e.target.value,
      page: 1,
      sortBy: "created_at",
      orderBy: "desc",
    });
    setNewFilters({ ...newFilters, limit: e.target.value, page: 1 });
    setGoToPage("");
  };

  const onSortBy = (label) => {
    if (label === "action") return;
    let orderBy = "";
    if (newFilters.orderBy === "desc") {
      orderBy = "asc";
    } else {
      orderBy = "desc";
    }
    const filters = { ...newFilters, sortBy: label, orderBy: orderBy };
    setNewFilters(filters);
    refreshCustomers(filters);
  };

  const onClickDownloadButton = () => {
    setDownloadingCsv(true);
    dispatch(actions.downloadCSVAllCustomers())
      .then((res) => {
        downloadFile(res.csvlink, "Devices")
          .then((res) => setDownloadingCsv(false))
          .catch((err) => setDownloadingCsv(false));
      })
      .catch((err) => {
        setDownloadingCsv(false);
      });
  };

  const onClickDownloadTrialCsvButton = () => {
    setTrialDownloadingCsv(true);
    dispatch(actions.downloadCSVTrialCustomers())
      .then((res) => {
        downloadFile(res.info.download_link, "Trial")
          .then((res) => setTrialDownloadingCsv(false))
          .catch((err) => setTrialDownloadingCsv(false));
      })
      .catch((err) => {
        setTrialDownloadingCsv(false);
      });
  };

  const onClickDownlodAutoSyncFeedbackCsv = () => {
    setAutoSyncFeedbackCsv(true);
    dispatch(actions.downloadAutoSyncFormCsv())
      .then((res) => {
        downloadFile(res.info.download_link, "Trial")
          .then((res) => setAutoSyncFeedbackCsv(false))
          .catch((err) => setAutoSyncFeedbackCsv(false));
      })
      .catch((err) => {
        setAutoSyncFeedbackCsv(false);
      });
  };

  const openDonwloadCsvModal = () => {
    setOpenDownloadCSVModal(true);
  };

  const onChangeGoToPage = ({ target: { value = "" } }) => {
    if (!value.match(/^[0-9]*$/) || value === "0") return;
    let pageValue = Math.ceil(total / newFilters.limit);
    if (value > pageValue) return;
    setGoToPage(value ? parseInt(value) : "");
    setNewFilters({ ...newFilters, page: value ? parseInt(value) : "" });
    delayGoToPagination({ ...newFilters, page: value });
  };

  const openFilterSidebar = () => {
    setNewFilters({ ...newFilters, isOpen: true });
  };

  const onCloseFiltersDrawer = () => {
    setNewFilters({ ...newFilters, isOpen: false });
  };

  const handleChangeAccordion = (panel, filterKey) => (event, isExpanded) => {
    if (!isExpanded) {
      setNewFilters({
        ...newFilters,
        [filterKey]: ["statusAcc"].includes(panel) ? [] : "",
        page: newFilters[filterKey]?.length ? 1 : newFilters.page,
      });
    }
    setAccordian({ ...accordion, [panel]: isExpanded });
  };

  const onChangeFilter = ({ target: { name = "", value = "" } }) => {
    let tempFilter = { ...newFilters };
    tempFilter = { ...tempFilter, [name]: value, page: 1 };
    setNewFilters(tempFilter);
  };

  const onSearch = (name, value) => {
    let tempFilter = { ...newFilters };
    tempFilter = { ...tempFilter, [name]: value, page: 1 };
    setNewFilters(tempFilter);
    delaySearch({ ...tempFilter });
  };

  const onChangeStatus = (key, value) => {
    let tempNewFilter = { ...newFilters, [key]: value };
    if (key === "address_type") {
      tempNewFilter = { ...tempNewFilter, page: 1 };
      setNewFilters(tempNewFilter);
    } else {
      let tempNewFilter = { ...newFilters };
      if (tempNewFilter[key]?.includes(value)) {
        let index = tempNewFilter[key].indexOf(value);
        tempNewFilter[key]?.splice(index, 1);
      } else {
        tempNewFilter[key]?.push(value);
      }
      tempNewFilter = { ...tempNewFilter, page: 1 };
      setNewFilters(tempNewFilter);
    }
  };
  const applyFilter = () => {
    refreshCustomers({ ...trimFilters(newFilters), page: 1 });
  };

  const clearFilter = () => {
    setClearFilterLoader(true);
    let tempFilter = {
      isOpen: newFilters.isOpen,
      email: "",
      full_name: "",
      phone_no: "",
      status: [],
      address_type: "",
      limit: 25,
      page: 1,
      sortBy: "",
      orderBy: "desc",
    };

    setNewFilters({ ...tempFilter });
    setAccordian({ ...initialAccordion });
    dispatch(actions.getAllCustomers(tempFilter)).then((res) => {
      setClearFilterLoader(false);
    });
  };

  const onChangeKeyDown = (e) => {
    if (e.key === "Enter") {
      refreshCustomers({ ...trimFilters(newFilters), page: 1 });
    }
  };

  const { t = () => {}, toast, classes } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container className="location-head con-space-between">
          <Grid item xs={12} sm={12} md={2} lg={2} className="heading">
            <h2>Customers </h2>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={7} style={{ borderColor: "white" }}>
            <div className="search-add ">
              {/* {!products.includes(selectedSideBarProduct) ? <Button
                                className="button-btn btn-custom-primary mr-3"
                                onClick={openDonwloadCsvModal}
                            >
                                Download CSV
                            </Button> : null} */}
              <IconButton aria-label="filter" onClick={openFilterSidebar}>
                <FilterListIcon />
                <Typography variant="body1" style={{ marginLeft: 8 }}>
                  Search
                </Typography>
              </IconButton>
              <CustomDrawer
                isOpen={newFilters.isOpen}
                onClose={onCloseFiltersDrawer}
                className={deviceType}
              >
                <ul className="list-style-list">
                  <CusBackdrop open={isLoading} className="loader-images" />

                  <li>
                    <CustomAccordion
                      isOpen={accordion.phoneAcc}
                      handleChange={handleChangeAccordion("phoneAcc", "phone_no")}
                      label={"Phone"}
                    >
                      <CustomSearchBar
                        placeholder="Enter Phone Number"
                        value={newFilters.phone_no}
                        onChange={onChangeFilter}
                        name={"phone_no"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>
                  <li>
                    <CustomAccordion
                      isOpen={accordion.emailAcc}
                      handleChange={handleChangeAccordion("emailAcc", "email")}
                      label={"Email"}
                    >
                      <CustomSearchBar
                        placeholder="Enter Email"
                        value={newFilters.email}
                        onChange={onChangeFilter}
                        name={"email"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>
                  <li>
                    <CustomAccordion
                      isOpen={accordion.nameAcc}
                      handleChange={handleChangeAccordion("nameAcc", "full_name")}
                      label={"Name"}
                    >
                      <CustomSearchBar
                        placeholder="Enter Name"
                        value={newFilters.full_name}
                        onChange={onChangeFilter}
                        name={"full_name"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>
                  <li>
                    <CustomAccordion
                      isOpen={accordion.statusAcc}
                      handleChange={handleChangeAccordion("statusAcc", "status")}
                      label={"Customer Status"}
                    >
                      <ul className="m-0 style-list">
                        <li>
                          <div
                            className={`${
                              newFilters?.status?.includes("Pending") ? "selected" : "unselected"
                            }`}
                            onClick={() => onChangeStatus("status", "Pending")}
                          >
                            {" "}
                            Pending{" "}
                          </div>
                        </li>
                        <li>
                          <div
                            className={`${
                              newFilters?.status?.includes("Completed") ? "selected" : "unselected"
                            }`}
                            onClick={() => onChangeStatus("status", "Completed")}
                          >
                            Completed
                          </div>
                        </li>
                        <li>
                          <div
                            className={`${
                              newFilters?.status?.includes("Incomplete") ? "selected" : "unselected"
                            }`}
                            onClick={() => onChangeStatus("status", "Incomplete")}
                          >
                            Incomplete
                          </div>
                        </li>
                        <li>
                          <div
                            className={`${
                              newFilters?.status?.includes("Deleted") ? "selected" : "unselected"
                            }`}
                            onClick={() => onChangeStatus("status", "Deleted")}
                          >
                            Deleted
                          </div>
                        </li>
                      </ul>
                    </CustomAccordion>
                  </li>
                  {selectedSideBarProduct === "heat_pump" ? (
                    <>
                      {/* <li>
                                                <CustomAccordion
                                                    isOpen={accordion.firstNameAcc}
                                                    handleChange={handleChangeAccordion('firstNameAcc', 'first_name')}
                                                    label={"First Name"}
                                                >
                                                    <CustomSearchBar
                                                        placeholder="Enter First Name"
                                                        value={newFilters.first_name}
                                                        onChange={onChangeFilter}
                                                        name={'first_name'}
                                                        onChangeKeyDown={onChangeKeyDown}
                                                    />
                                                </CustomAccordion>
                                            </li>
                                            <li>
                                                <CustomAccordion
                                                    isOpen={accordion.lastNameAcc}
                                                    handleChange={handleChangeAccordion('lastNameAcc', 'last_name')}
                                                    label={"Last Name"}
                                                >
                                                    <CustomSearchBar
                                                        placeholder="Enter Last Name"
                                                        value={newFilters.last_name}
                                                        onChange={onChangeFilter}
                                                        name={'last_name'}
                                                        onChangeKeyDown={onChangeKeyDown}
                                                    />
                                                </CustomAccordion>
                                            </li> */}
                      <li>
                        <CustomAccordion
                          isOpen={accordion.addressTypeAcc}
                          handleChange={handleChangeAccordion("addressTypeAcc", "address_type")}
                          label={"Property Type"}
                        >
                          <ul className="m-0 style-list">
                            <li>
                              <div
                                className={`${
                                  newFilters?.address_type == "residential"
                                    ? "selected"
                                    : "unselected"
                                }`}
                                onClick={() => onChangeStatus("address_type", "residential")}
                              >
                                Residential
                              </div>
                            </li>
                            <li>
                              <div
                                className={`${
                                  newFilters?.address_type == "commercial"
                                    ? "selected"
                                    : "unselected"
                                }`}
                                onClick={() => onChangeStatus("address_type", "commercial")}
                              >
                                Commercial
                              </div>
                            </li>
                          </ul>
                        </CustomAccordion>
                      </li>
                    </>
                  ) : null}
                  <li>
                    <SearchFilterButtons
                      clearFilter={clearFilter}
                      applyFilter={applyFilter}
                      isLoading={isLoading}
                      clearFilterLoader={clearFilterLoader}
                    />
                  </li>
                </ul>
              </CustomDrawer>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} className="content-container ">
        <div id="no-more-tables" className="card-design table-design cf">
          {/* <Grid item className="searchBar">
                        <TableSearch placeholder={"Search by Custumer name "} searchValue={searchValue} changehandler={(value) => {
                            onSearch("first_name" ,value)   
                            setSearchValue(value)
                        
                            }} />
                    </Grid> */}
          {isLoading ? (
            <>
              <TableContainer className={classes.container} style={{ overflow: "hidden" }}>
                <Table className="table-program" stickyHeader aria-label="sticky table">
                  <EnhancedTableHead
                    headCells={
                      selectedSideBarProduct === "electricity-advisor" ||
                      selectedSideBarProduct === "all"
                        ? manageCustomersHead
                        : selectedSideBarProduct === "heat_pump"
                        ? manageCustomersHeatPump
                        : manageCustomersLiveLinkHead
                    }
                  />
                </Table>
              </TableContainer>
              <TableLoader />
            </>
          ) : null}

          {!isLoading ? (
            <Grid container direction="column">
              <Grid item>
                <TableContainer className={classes.container}>
                  <Table className="table-program" stickyHeader aria-label="sticky table">
                    <EnhancedTableHead
                      headCells={
                        selectedSideBarProduct === "electricity-advisor" ||
                        selectedSideBarProduct === "all"
                          ? manageCustomersHead
                          : selectedSideBarProduct === "heat_pump"
                          ? manageCustomersHeatPump
                          : manageCustomersLiveLinkHead
                      }
                      onSortBy={onSortBy}
                      filters={newFilters}
                    />
                    {selectedSideBarProduct === "heat_pump" ? (
                      <TableBody>
                        {customers?.length ? (
                          customers.map((item, index) => {
                            const {
                              status = "",
                              auto_sync,
                              weekly_report = "",
                              firstproperty = {},
                            } = item;
                            let statusCSS =
                              status === "Completed"
                                ? "green"
                                : status === "Incomplete"
                                ? "blue"
                                : status === "Pending"
                                ? "yellow"
                                : status === "Deleted"
                                ? "red"
                                : "";
                            const isSelected =
                              selected.find((selItem) => selItem.id === item.id) || false;
                            let address = propertyAddressFormat(
                              firstproperty?.unit_number,
                              firstproperty?.street_number,
                              firstproperty?.street,
                              firstproperty?.city,
                              firstproperty?.state,
                              firstproperty?.postal_code
                            );
                            let addressTypeCSS =
                              firstproperty?.address_type === "residential"
                                ? "residential"
                                : "commercial";

                            return (
                              <TableRow
                                key={item.id}
                                hover
                                role="checkbox"
                                className="cursor_default"
                              >
                                <TableCell className="table-custom-width">
                                  {item?.email || "-"}
                                </TableCell>
                                <TableCell className="table-custom-width">
                                  {item?.full_name || "-"}
                                </TableCell>
                                <TableCell className="table-custom-width">
                                  {address || "-"}
                                </TableCell>
                                <TableCell className="table-custom-width">
                                  {item?.phone_no || "-"}
                                </TableCell>
                                <TableCell className={`${addressTypeCSS} table-custom-width`}>
                                  {getTextFormat(firstproperty?.address_type) || "-"}
                                </TableCell>
                                <TableCell
                                  className={`table-custom-width customers_badge ${statusCSS}`}
                                >
                                  {item?.status}
                                </TableCell>
                                {(selectedSideBarProduct === "electricity-advisor" ||
                                  selectedSideBarProduct === "all") && (
                                  <TableCell className={`table-custom-width`}>
                                    {auto_sync !== null ? (
                                      <LightTooltip title="Click here to view Auto Sync History">
                                        <div
                                          onClick={() =>
                                            history.push(
                                              `/agency/customer/auto-sync/${
                                                item.id || ""
                                              }?synctype=auto_sync`
                                            )
                                          }
                                        >
                                          {auto_sync ? (
                                            <div className=" trial">Yes</div>
                                          ) : (
                                            <div className=" trial-no">No</div>
                                          )}
                                        </div>
                                      </LightTooltip>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                )}
                                {(selectedSideBarProduct === "electricity-advisor" ||
                                  selectedSideBarProduct === "all") && (
                                  <TableCell className={`table-custom-width`}>
                                    {weekly_report !== null ? (
                                      <LightTooltip title="Click here to view Weekly Report History">
                                        <div
                                          onClick={() =>
                                            history.push(
                                              `/agency/customer/auto-sync/${
                                                item.id || ""
                                              }?synctype=weekly_report`
                                            )
                                          }
                                        >
                                          {weekly_report ? (
                                            <div className=" trial">Yes</div>
                                          ) : (
                                            <div className=" trial-no">No</div>
                                          )}
                                        </div>
                                      </LightTooltip>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                )}
                                <TableCell className="table-custom-width more">
                                  <Link
                                    to={`/${
                                      userType == "mscc"
                                        ? "multisite"
                                        : userType == "sm"
                                        ? "sitemanager"
                                        : userType.toLowerCase()
                                    }/customer/view/${item.id}${location?.search}`}
                                    className="link-tag"
                                  >
                                    <VisibilityIcon className="show-pointer" />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{ border: "none" }}
                              colSpan="8"
                              className="table-no-data"
                            >
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        {customers?.length ? (
                          customers.map((item, index) => {
                            const { status = "", auto_sync, weekly_report = "" } = item;
                            let statusCSS =
                              status === "Completed"
                                ? "green"
                                : status === "Incomplete"
                                ? "blue"
                                : status === "Pending"
                                ? "yellow"
                                : status === "Deleted"
                                ? "red"
                                : "";
                            return (
                              <TableRow
                                key={item.id}
                                hover
                                role="checkbox"
                                className="cursor_default"
                              >
                                <TableCell className="table-custom-width">
                                  {item?.email || "-"}
                                </TableCell>
                                <TableCell className="table-custom-width">
                                  {item?.full_name || "-"}
                                </TableCell>
                                <TableCell className="table-custom-width">
                                  {item?.phone_no || "-"}
                                </TableCell>
                                <TableCell className={`table-custom-width ${statusCSS}`}>
                                  {item?.status}
                                </TableCell>
                                {(selectedSideBarProduct === "electricity-advisor" ||
                                  selectedSideBarProduct === "all") && (
                                  <TableCell className={`table-custom-width`}>
                                    {auto_sync !== null ? (
                                      <LightTooltip title="Click here to view Auto Sync History">
                                        <div
                                          onClick={() =>
                                            history.push(
                                              `/agency/customer/auto-sync/${
                                                item.id || ""
                                              }?synctype=auto_sync`
                                            )
                                          }
                                        >
                                          {auto_sync ? (
                                            <div className=" trial">Yes</div>
                                          ) : (
                                            <div className=" trial-no">No</div>
                                          )}
                                        </div>
                                      </LightTooltip>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                )}
                                {(selectedSideBarProduct === "electricity-advisor" ||
                                  selectedSideBarProduct === "all") && (
                                  <TableCell className={`table-custom-width`}>
                                    {weekly_report !== null ? (
                                      <LightTooltip title="Click here to view Weekly Report History">
                                        <div
                                          onClick={() =>
                                            history.push(
                                              `/agency/customer/auto-sync/${
                                                item.id || ""
                                              }?synctype=weekly_report`
                                            )
                                          }
                                        >
                                          {weekly_report ? (
                                            <div className=" trial">Yes</div>
                                          ) : (
                                            <div className=" trial-no">No</div>
                                          )}
                                        </div>
                                      </LightTooltip>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                )}
                                <TableCell className="table-custom-width more">
                                  <Link
                                    to={`/agency/customer/view/${item.id}${location?.search}`}
                                    className="link-tag"
                                  >
                                    <VisibilityIcon className="show-pointer" />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              style={{ border: "none" }}
                              colSpan="8"
                              className="table-no-data"
                            >
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ) : null}

          <CustomTablePagination
            count={total}
            refresh={refresh}
            onClickRefresh={onClickRefresh}
            filters={newFilters}
            onPageChange={onPageChange}
            onChangeLimit={onChangeLimit}
            onChangeGoToPage={onChangeGoToPage}
            goToPage={goToPage}
          />
        </div>
      </Grid>
      <Dialog
        open={openDownloadCSVModal}
        onClose={() => {
          setOpenDownloadCSVModal(false);
        }}
        aria-labelledby="form-dialog-title"
        className=" cus-dialog py-3"
        fullWidth
      >
        <DialogTitle>Download CSV</DialogTitle>
        <DialogContent className="dialog-btns customer-csv">
          <div className="download-container">
            {selectedSideBarProduct === "electricity-advisor" ? (
              <div className="csv-box">
                <div>Survey Feedback</div>
                {!isTrialDownloadingCsv ? (
                  <div className="download-icon" onClick={onClickDownloadTrialCsvButton}>
                    <img src="/images/download-2-fill.svg" />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <CircularProgress
                      size={18}
                      style={{ color: "green", marginRight: 10, marginTop: "4px" }}
                    />
                  </div>
                )}
              </div>
            ) : null}
            {selectedSideBarProduct === "electricity-advisor" ? (
              <div className="csv-box ">
                <div> Auto-Sync feedback</div>
                {!isDownloadAutoSyncCsv ? (
                  <div className="download-icon" onClick={onClickDownlodAutoSyncFeedbackCsv}>
                    <img src="/images/download-2-fill.svg" />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <CircularProgress
                      size={18}
                      style={{ color: "green", marginRight: 10, marginTop: "4px" }}
                    />
                  </div>
                )}
              </div>
            ) : null}
            {/* <div className="csv-box">
                            <div>Customer CSV</div>
                            {
                                !isDownloadingCsv ? <div className="download-icon" onClick={onClickDownloadButton}>
                                    <img src="/images/download-2-fill.svg" />
                                </div> :
                                    <div> <CircularProgress size={18} style={{ color: "green", marginRight: 10, marginTop: "4px" }} /></div>
                            }
                        </div> */}
          </div>
        </DialogContent>
        <DialogActions className="dialog-btns modal-add-pdf">
          <Button
            onClick={() => setOpenDownloadCSVModal(false)}
            className="button-btn btn-custom-black mr-3"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default withTranslation("translations")(withStyles(tablestyle)(Customers));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);
