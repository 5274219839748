import { isEmpty } from 'lodash';
import Validator from 'is_js'
export const faultySystemValidation = (data) => {
    const { phone = "" } = data || {}
    let errors = {};
    const emailRegex = /^.+@.+\..+$/

    if (!data.agent) {
        errors.agent = "Agent is required";
    }
    if (data?.job_comments?.length > 500) {
        errors.job_comments = "Please enter less than 500 characters";
    }
    return {
        isValid: isEmpty(errors),
        errors
    };;
}