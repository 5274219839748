import *  as commonActions from "../../common/actions"

import { errorMessage } from '../../../utils'
import {
    getUserCountAndVersionAPI,
    getdevicegraphdataAPI,
    getDeviceOverviewDataAPI,
    getDashboardDeviceByMonthAPI,
    getConsumptionGraphDataAPI,
    getConsumptionOverviewAPI
} from './apis'

export const GET_USER_COUNT_AND_VERSION_REQUEST = 'GET_USER_COUNT_AND_VERSION_REQUEST';
export const GET_USER_COUNT_AND_VERSION_SUCCESS = 'GET_USER_COUNT_AND_VERSION_SUCCESS';
export const GET_USER_COUNT_AND_VERSION_FAILED = 'GET_USER_COUNT_AND_VERSION_FAILED';

export const getUserCountAndVersion = () => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_USER_COUNT_AND_VERSION_REQUEST
        })
        getUserCountAndVersionAPI().then(res => {
            dispatch({
                type: GET_USER_COUNT_AND_VERSION_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_USER_COUNT_AND_VERSION_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_DEVICE_GRAPH_DATA_REQUEST = 'GET_DEVICE_GRAPH_DATA_REQUEST';
export const GET_DEVICE_GRAPH_DATA_SUCCESS = 'GET_DEVICE_GRAPH_DATA_SUCCESS';
export const GET_DEVICE_GRAPH_DATA_FAILED = 'GET_DEVICE_GRAPH_DATA_FAILED';

export const getdevicegraphdata = (year) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DEVICE_GRAPH_DATA_REQUEST
        })
        getdevicegraphdataAPI(year).then(res => {
            dispatch({
                type: GET_DEVICE_GRAPH_DATA_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DEVICE_GRAPH_DATA_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_DEVICE_OVERVIEW_DATA_REQUEST = 'GET_DEVICE_OVERVIEW_DATA_REQUEST';
export const GET_DEVICE_OVERVIEW_DATA_SUCCESS = 'GET_DEVICE_OVERVIEW_DATA_SUCCESS';
export const GET_DEVICE_OVERVIEW_DATA_FAILED = 'GET_DEVICE_OVERVIEW_DATA_FAILED';

export const getDeviceOverviewData = () => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DEVICE_OVERVIEW_DATA_REQUEST
        })
        getDeviceOverviewDataAPI().then(res => {
            dispatch({
                type: GET_DEVICE_OVERVIEW_DATA_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DEVICE_OVERVIEW_DATA_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_DASHBOARD_DEVICES_MONTH_REQUEST = "GET_DASHBOARD_DEVICES_MONTH_REQUEST";
export const GET_DASHBOARD_DEVICES_MONTH_SUCCESS = "GET_DASHBOARD_DEVICES_MONTH_SUCCESS";
export const GET_DASHBOARD_DEVICES_MONTH_FAILED = "GET_DASHBOARD_DEVICES_MONTH_FAILED";

export const getDashboardDeviceByMonth = (filters, year, deviceStatus) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_DASHBOARD_DEVICES_MONTH_REQUEST
        })
        getDashboardDeviceByMonthAPI(filters, year, deviceStatus).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_DASHBOARD_DEVICES_MONTH_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DASHBOARD_DEVICES_MONTH_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CLEAR_ALL_DEVICE_DATA = 'CLEAR_ALL_DEVICE_DATA';

export const clearStoreForDashboard = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_DEVICE_DATA
        })
    }
}

export const GET_CONSUMPTION_GRAPH_DATA_REQUEST = 'GET_CONSUMPTION_GRAPH_DATA_REQUEST';
export const GET_CONSUMPTION_GRAPH_DATA_SUCCESS = 'GET_CONSUMPTION_GRAPH_DATA_SUCCESS';
export const GET_CONSUMPTION_GRAPH_DATA_FAILED = 'GET_CONSUMPTION_GRAPH_DATA_FAILED';

export const getConsumptionGraphData = (year) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_CONSUMPTION_GRAPH_DATA_REQUEST
        })
        getConsumptionGraphDataAPI(year).then(res => {
            dispatch({
                type: GET_CONSUMPTION_GRAPH_DATA_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CONSUMPTION_GRAPH_DATA_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_CONSUMPTION_OVERVIEW_REQUEST = 'GET_CONSUMPTION_OVERVIEW_REQUEST';
export const GET_CONSUMPTION_OVERVIEW_SUCCESS = 'GET_CONSUMPTION_OVERVIEW_SUCCESS';
export const GET_CONSUMPTION_OVERVIEW_FAILED = 'GET_CONSUMPTION_OVERVIEW_FAILED';

export const getConsumptionOverview = () => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_CONSUMPTION_OVERVIEW_REQUEST
        })
        getConsumptionOverviewAPI().then(res => {
            dispatch({
                type: GET_CONSUMPTION_OVERVIEW_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CONSUMPTION_OVERVIEW_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
