import * as actionsTypes from "./actions";

const initialState = {
  isLoading: false,
  multiSiteDetails: {},
  multiSiteDevicesForTable: {},
  multiSiteDevicesIsLoading: false,
  sitesForTable: {},
  sitesIsLoading: false,
  loadingConsumptionHistory: false,
  consumptionHistory: {},
};

export default function (state = { ...initialState }, action) {
  switch (action.type) {
    case actionsTypes.GET_MULTISITE_BY_ID_REQUEST:
      return { ...state, isLoading: true };

    case actionsTypes.GET_MULTISITE_BY_ID_SUCESS:
      return { ...state, isLoading: false, multiSiteDetails: action.payload || {} };

    case actionsTypes.GET_MULTISITE_BY_ID_FAILED:
      return { ...state, isLoading: false };

    case actionsTypes.GET_DEVICE_CONSUMPTION_BY_MSCID_REQUEST:
      return { ...state, loadingConsumptionHistory: true };

    case actionsTypes.GET_DEVICE_CONSUMPTION_BY_MSCID_SUCCESS:
      return {
        ...state,
        loadingConsumptionHistory: false,
        consumptionHistory: action.payload || {},
      };

    case actionsTypes.GET_DEVICE_CONSUMPTION_BY_MSCID_FAILED:
      return { ...state, loadingConsumptionHistory: false };

    case actionsTypes.GET_MULTI_SITE_DEVICES_FOR_TABLE_REQUEST:
      return { ...state, multiSiteDevicesIsLoading: true };

    case actionsTypes.GET_MULTI_SITE_DEVICES_FOR_TABLE_SUCCESS:
      return {
        ...state,
        multiSiteDevicesIsLoading: false,
        multiSiteDevicesForTable: action.payload || {},
      };

    case actionsTypes.GET_MULTI_SITE_DEVICES_FOR_TABLE_FAILED:
      return { ...state, multiSiteDevicesIsLoading: false };

    case actionsTypes.GET_SITES_FOR_TABLE_REQUEST:
      return { ...state, sitesIsLoading: true };

    case actionsTypes.GET_SITES_FOR_TABLE_SUCCESS:
      return { ...state, sitesIsLoading: false, sitesForTable: action.payload || {} };

    case actionsTypes.GET_SITES_FOR_TABLE_FAILED:
      return { ...state, sitesIsLoading: false };

    case actionsTypes.CLEAR_MULTISITE_DETAIL:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
