
import * as Auth from './Auth'
import * as InstallationAgent from './InstallationAgent'
import * as Agency from './Agency'
import * as ElectricityRetailer from './ElectricityRetailer'
import * as Tariff from './Tariff'
import * as InstallerAgent from './installerAgent'
import * as Customer from './Customer'
import * as AdminDevice from './AdminDevice'
import * as AgencyDevice from './AgencyDevice'
import * as Firmware from './firmware'
import * as Rule from './rule'
import * as Group from './Group'
import * as Electrician from './AddElectrician'
import * as Aggregator from './Aggregator'
import * as ElectricianProfile from './ElectricianProfile'
import *  as AdminProfileValidate  from './AdminProfile'
import *  as AddMember  from './addMember'
import * as SliderValidation  from './SliderValidation'


export default {
    ...Auth,
    ...InstallationAgent,
    ...Agency,
    ...ElectricityRetailer,
    ...Tariff,
    ...InstallerAgent,
    ...Customer,
    ...AdminDevice,
    ...AgencyDevice,
    ...Firmware,
    ...Rule,
    ...Group,
    ...Electrician,
    ...Aggregator,
    ...ElectricianProfile,
    ...AdminProfileValidate,
    ...AddMember,
    ...SliderValidation
}