import { apiGet, apiPut, getDeviceType, isLoggedIn } from "../../../utils";
import moment from "moment";

const { userType } = isLoggedIn();

export function getAllFaultySystemAPI(filters) {
  let arr = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;

    if (typeof value !== "number" && !value?.length) continue;

    if (key === "installation_date") {
      if (value[0] == null) continue;
      arr.push({
        key,
        value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
        end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
        type: "single",
      });
    } else if (["device_status", "status", "connection_type"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value: encodeURIComponent(value), type: "single" });
    }
  }

  return apiGet(`/api/agency/nms/fault/get?filter=${JSON.stringify(arr)}`, null, null);
}

// export function getSingleHeatPumpDeviceAPI(id) {
export function getSingleFaultySystemAPI(id) {
  return apiGet(`/api/agency/heat-pump/info/${id}`, null, "safelink");
}

export function getFaultySystemAPIHistoryBySerialNoAPI(mac_address) {
  return apiGet(
    `/api/agency/heat-pump/install-history?mac_address=${mac_address}`,
    null,
    "safelink"
  );
}
export function getFaultyHistoryAPI(device_id) {
  return apiGet(
    `/api/${userType.toLowerCase()}/nms/fault/get-fault-history?device_id=${device_id}&device_type=${getDeviceType()}`
  );
}
export function updateFaultAPI(data) {
  return apiPut(`/api/agency/nms/fault/update`, data);
}
export function newAgencyFaultAPI(isUpdate) {
  if (isUpdate) {
    return apiPut(`/api/agency/nms/fault/new-fault`);
  } else {
    return apiGet(`/api/agency/nms/fault/new-fault`);
  }
}

// Apius for MultiSite Details (Agency Multisite View)
export function getMultiSiteByIdAPI(id) {
  return apiGet(`/api/agency/nms/msc/get/${id}`);
}

export function getDeviceConsumptionHistoryMscId(mscId) {
  return apiGet(`/api/agency/nms/msc/${mscId}/consumption-history?deviceType=${getDeviceType()}`);
}

export function getMultiSiteDevicesForTableAPI(id) {
  return apiGet(
    `/api/agency/nms/msc/devices/get/all?filter=[]&multi_site_company_id=${id}&deviceType=heat_pump`
  );
  // agency/nms/msc/devices/get/all?filter=[]&multi_site_company_id=d03129ac-1db2-4464-ba99-b6ee63ea0465&deviceType=heat_pump
}
export function getSitesForTableAPI(id) {
  // agency/nms/msc/sites/get/all?multi_site_company_id=d03129ac-1db2-4464-ba99-b6ee63ea0465&filter=[]
  return apiGet(`/api/agency/nms/msc/sites/get/all?multi_site_company_id=${id}&filter=[]`);
}
