import { all } from "is_js";
import * as actionsTypes from "./actions";

const initialState = {
  isLoading: false,
  allHeatPumpDevices: {},
  singleHeatpumpDevice: {},
  deviceHistory: [],
  allMultisites: {},
  allMscLoading: false,
  mscSites: {},
  mscSitesLoading: false,
};

export default function (state = { ...initialState }, action) {
  switch (action.type) {
    case actionsTypes.GET_HEAT_PUMP_DEVICES_REQUEST:
      return { ...state, isLoading: true };

    case actionsTypes.GET_HEAT_PUMP_DEVICES_SUCCESS:
      return { ...state, isLoading: false, allHeatPumpDevices: action.payload || {} };

    case actionsTypes.GET_HEAT_PUMP_DEVICES_FAILED:
      return { ...state, isLoading: false };

    case actionsTypes.GET_SINGLE_HEAT_PUMP_REQUEST:
      return { ...state, isLoading: true };

    case actionsTypes.GET_SINGLE_HEAT_PUMP_SUCCESS:
      return { ...state, isLoading: false, singleHeatpumpDevice: action.payload || {} };

    case actionsTypes.GET_SINGLE_HEAT_PUMP_FAILED:
      return { ...state, isLoading: false };

    case actionsTypes.GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST:
      return { ...state, isLoading: true, deviceHistory: [] };

    case actionsTypes.GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS:
      return { ...state, isLoading: false, deviceHistory: action.payload || [] };

    case actionsTypes.GET_HEAT_PUMP_DEVICE_HISTORY_FAILED:
      return { ...state, isLoading: false };

    case actionsTypes.GET_ALL_MULTISITES_REQUEST:
      return { ...state, allMscLoading: true };

    case actionsTypes.GET_ALL_MULTISITES_SUCCESS:
      return { ...state, allMscLoading: false, allMultisites: action.payload };

    case actionsTypes.GET_ALL_MULTISITES_FAILED:
      return { ...state, allMscLoading: false };

    case actionsTypes.GET_SITES_REQUEST:
      return { ...state, mscSitesLoading: true };

    case actionsTypes.GET_SITES_SUCCESS:
      return { ...state, mscSitesLoading: false, mscSites: action.payload };

    case actionsTypes.GET_SITES_FAILED:
      return { ...state, mscSitesLoading: false };

    default:
      return state;
  }
}
