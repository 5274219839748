import { apiGet, getDeviceType } from "../../../../../utils";

export function getDashboardCountDataAPI() {
    return apiGet(`/api/nms/dashboard/counts?deviceType=${getDeviceType()}`, null, null)
} 

export function getOnlineOfflineDataAPI(year) {
    return apiGet(`/api/nms/dashboard/month-online-offline-device?year=${year}&deviceType=${getDeviceType()}`, null, null)
} 

export function getFaultNonFaultyDataAPI(year) {
    return apiGet(`/api/nms/dashboard/month-faulty-device?year=${year}&deviceType=${getDeviceType()}`, null, null)
} 

// 


// export function getdevicegraphdataAPI(year) {
//     return apiGet(`/api/dashboard/getdevicegraphdata?year=${year}&deviceType=${getDeviceType()}`)
// }

// export function getDeviceOverviewDataAPI() {
//     return apiGet(`/api/dashboard/getdeviceoverview?deviceType=${getDeviceType()}`)
// }

// export function getDashboardDeviceByMonthAPI(filters, year, deviceStatus = 'All') {

//     let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', month = '' } = filters || {}

//     let deviceType = getSelectedDeviceType() || "electricity-advisor"

//     let urlOption = {
//         "electricity-advisor": "month-device",
//         "livelink": "month-device-livelink",
//         "safelink": "month-device-safelink",
//         "airlink": "month-device-airlink",
//         "bulb":'month-device-bulb',
//         "heat_pump":'month-device-heat_pump',
//     }

//     return apiGet(`/api/dashboard/${urlOption[deviceType]}?month=${month}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&year=${year}&deviceStatus=${deviceStatus}`)
// }