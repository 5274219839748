import Validator from 'is_js'
import { isEmpty } from "lodash";
import moment from 'moment';
import { doConsole, scrollIntoView } from "../../utils";

export function tariff(data) {
    const { tariffData } = data
    const { typeTarrif } = tariffData
    let errors = {}
    let typeError = [];
    let isError = false;

    // Check if tariff company not exist
    if (tariffData && !tariffData.company) {
        errors = {
            ...errors,
            company: 'Company is required'
        }

        if (!isError) {
            isError = true;
            scrollIntoView('company_name')
        }
    }

    // Check if tariff postcode not exist
    if (tariffData && tariffData.postCode && !tariffData.postCode.length) {
        errors = {
            ...errors,
            postCode: 'Post Code is required. Please upload valid CSV file.'
        }
        if (!isError) {
            isError = true;
            scrollIntoView('Postcode-error', 120)
        }
    }

    // here typetariff loop run
    typeTarrif.forEach(({ type, season, flatRate }, typeTarrifIndex) => {

        let seasonError = [];
        let error = {};

        // here if check type is exist 
        // If type is exist then should not be same
        if (!type) {
            error = {
                ...error,
                typeError: 'Type is required'
            }
            if (!isError) {
                isError = true;
                scrollIntoView(`Type-${typeTarrifIndex}`, 120);
            }
        } else {
            for (let i = 0; i < typeTarrif.length; i++) {
                if (typeTarrifIndex === i) continue
                else {
                    if (type === typeTarrif[i].type) {
                        error = {
                            ...error,
                            typeError: 'Type cannot be same'
                        }
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`Type-${typeTarrifIndex}`, 120);
                        }
                    }
                }
            }
        }

        // initial season days set by default 0
        let totalNoOfSeasonDays = 0;

        // check if flatRate not exist
        if (!flatRate) {
            season.forEach(({ weekdays, weekends, from, to, sameAsWeekendsToggle, selectedFromMonthDay = 0, selectedToMonthDay = 0, selectedFromMonth = 0, selectedToMonth = 0 }, seasonIndex) => {

                // here initialise total minute of peak in weekdays and weekends by default 0

                let totalNoOfPeakMinutesWeekdays = 0
                let totalNoOfPeakMinutesWeekends = 0
                from = parseInt(from)
                to = parseInt(to)

                // set errorseason blank object
                let errorSeason = {}

                // here check date From is exist
                if (!from || from === 0 || selectedFromMonthDay === 0 || !selectedFromMonthDay || !selectedFromMonth || selectedFromMonth === 0) {
                    errorSeason = {
                        ...errorSeason,
                        from: 'Season start Date is required'
                    }
                    if (!isError) {
                        isError = true;
                        scrollIntoView(`Season-start-date-${typeTarrifIndex}-${seasonIndex}`, 120);
                    }
                }

                // here check date To is exist
                if (!to || to === 0 || selectedToMonthDay === 0 || !selectedToMonthDay || !selectedToMonth || selectedToMonth === 0) {
                    errorSeason = {
                        ...errorSeason,
                        to: 'Season end Date is required'
                    }
                    if (!isError) {
                        isError = true;
                        scrollIntoView(`Season-end-date-${typeTarrifIndex}-${seasonIndex}`, 120);
                    }
                }

                let from1 = moment(moment(from).format('MM-DD-YYYY')).valueOf();
                let to1 = moment(moment(to).format('MM-DD-YYYY')).valueOf();
                let diff = moment(to1).diff(moment(from1), "days");

                console.log(from1, "from1")
                console.log(to1, "to1")
                console.log(diff, "diff")

                if (diff && seasonIndex !== 0) {
                    totalNoOfSeasonDays = totalNoOfSeasonDays + diff + 1
                }
                else {
                    totalNoOfSeasonDays += diff;
                }

                let { peak = [], offPeak = [], shoulder = [], other = [] } = weekdays


                peak.forEach(({ startTime, endTime, name }, index) => {

                    // console.log(moment(startTime).format('YYYY-MM-DD hh:mm'));
                    if (startTime && endTime) {

                        // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)
                        endTime = endTime + 60000
                        const diff = moment(endTime).diff(moment(startTime), "minute")
                        if (diff > 1) totalNoOfPeakMinutesWeekdays += diff
                    }
                    // console.log(diff, totalNoOfPeakMinutesWeekdays, "peak")

                    // here we are checking  peak name exist
                    if ((index === 0) && (!name)) {
                        errorSeason = {
                            ...errorSeason,
                            DayPeakNameError: 'Name is required'
                        }
                        if (!isError) {
                            isError = true;
                            // console.log(`Peak-name-weekday-${typeTarrifIndex}-${seasonIndex}-${index} `)
                            scrollIntoView(`Peak-name-weekday-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                        }
                    }
                })

                offPeak.forEach(({ startTime, endTime, name }, index) => {

                    if (startTime && endTime) {
                        // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)

                        endTime = endTime + 60000
                        const diff = moment(endTime).diff(moment(startTime), "minute")
                        if (diff > 1) totalNoOfPeakMinutesWeekdays += diff
                        // console.log(diff, totalNoOfPeakMinutesWeekdays, "offpeak")

                    }

                    // here we are checking  offPeak name exist
                    if ((index === 0) && (!name)) {
                        errorSeason = {
                            ...errorSeason,
                            DayOffPeakNameError: 'Name is required'
                        }
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`OffPeak-name-weekday-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                        }
                    }
                })

                shoulder.forEach(({ startTime, endTime, name }, index) => {
                    if (startTime && endTime) {

                        // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)
                        endTime = endTime + 60000
                        const diff = moment(endTime).diff(moment(startTime), "minute")
                        if (diff > 1) totalNoOfPeakMinutesWeekdays += diff
                        // console.log(diff, totalNoOfPeakMinutesWeekdays, "shoulder")

                    }

                    // here we are checking  shoulder name exist
                    if ((index === 0) && (!name)) {
                        errorSeason = {
                            ...errorSeason,
                            DayshoulderNameError: 'Name is required'
                        }
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`Shoulder-name-weekday-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                        }
                    }
                })


                other.forEach(({ startTime, endTime, name }, index) => {
                    if (startTime && endTime) {

                        // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)
                        endTime = endTime + 60000
                        const diff = moment(endTime).diff(moment(startTime), "minute")
                        if (diff > 1) totalNoOfPeakMinutesWeekdays += diff
                        // console.log(diff, totalNoOfPeakMinutesWeekdays, "other")

                    }

                    // here we are checking  other name exist
                    if ((index === 0) && (!name)) {
                        errorSeason = {
                            ...errorSeason,
                            DayOtherNameError: 'Name is required'
                        }
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`Other-name-weekday-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                        }
                    }
                })
                // console.log(totalNoOfPeakMinutesWeekdays, "totalNoOfPeakMinutesWeekdays oooooooooooooooooo")

                // here checking total weekdays time 24 hours 
                if (totalNoOfPeakMinutesWeekdays !== 1440) {
                    // console.log(totalNoOfPeakMinutesWeekdays, "totalNoOfPeakMinutesWeekdays")
                    errorSeason = {
                        ...errorSeason,
                        weekdaysTimeError: 'Weekday should be of 24 Hours'
                    }
                    if (!isError) {
                        isError = true;
                        scrollIntoView(`Total-Time-Weekdays-${typeTarrifIndex}-${seasonIndex}`, 120);
                    }
                }



                // if same as weekend toggle is not selected
                if (!sameAsWeekendsToggle) {

                    const { peak = [], offPeak = [], shoulder = [], other = [] } = weekends;

                    peak.forEach(({ startTime, endTime, name }, index) => {

                        if (startTime && endTime) {

                            // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)
                            endTime = endTime + 60000
                            const diff = moment(endTime).diff(moment(startTime), "minute")
                            if (diff > 1) totalNoOfPeakMinutesWeekends += diff
                            // console.log(diff, totalNoOfPeakMinutesWeekends, "weekends peak")


                        }

                        // here we are checking  peak name exist
                        if ((index === 0) && (!name)) {
                            errorSeason = {
                                ...errorSeason,
                                EndPeakNameError: 'Name is required'
                            }
                            if (!isError) {
                                isError = true;
                                scrollIntoView(`Peak-name-weekend-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                            }
                        }

                    })

                    offPeak.forEach(({ startTime, endTime, name }, index) => {

                        if (startTime && endTime) {

                            // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)
                            endTime = endTime + 60000
                            const diff = moment(endTime).diff(moment(startTime), "minute")
                            if (diff > 1) totalNoOfPeakMinutesWeekends += diff
                            // console.log(diff, totalNoOfPeakMinutesWeekends, "weekends offpeak")

                        }

                        // here we are checking  offPeak name exist
                        if ((index === 0) && (!name)) {
                            errorSeason = {
                                ...errorSeason,
                                EndOffPeakNameError: 'Name is required'
                            }
                            if (!isError) {
                                isError = true;
                                scrollIntoView(`Offpeak-name-weekend-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                            }
                        }

                    })

                    shoulder.forEach(({ startTime, endTime, name }, index) => {
                        if (startTime && endTime) {

                            // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)
                            endTime = endTime + 60000
                            const diff = moment(endTime).diff(moment(startTime), "minute")
                            if (diff > 1) totalNoOfPeakMinutesWeekends += diff
                            // console.log(diff, totalNoOfPeakMinutesWeekends, "weekends shoulder")

                        }

                        // here we are checking  shoulder name exist
                        if ((index === 0) && (!name)) {
                            errorSeason = {
                                ...errorSeason,
                                EndShoulderNameError: 'Name is required'
                            }
                            if (!isError) {
                                isError = true;
                                scrollIntoView(`Shoulder-name-weekend-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                            }
                        }
                    })

                    other.forEach(({ startTime, endTime, name }, index) => {

                        if (startTime && endTime) {

                            // here we are adding 1 minute if startime and endtime exists, because endtime time we are selecting 1 min less (like 13:59:00)
                            endTime = endTime + 60000
                            const diff = moment(endTime).diff(moment(startTime), "minute")
                            if (diff > 1) totalNoOfPeakMinutesWeekends += diff
                            // console.log(diff, totalNoOfPeakMinutesWeekends, "weekends other")

                        }

                        // here we are checking  other name exist
                        if ((index === 0) && (!name)) {
                            errorSeason = {
                                ...errorSeason,
                                EndOtherNameError: 'Name is required'
                            }
                            if (!isError) {
                                isError = true;
                                scrollIntoView(`Other-name-weekend-${typeTarrifIndex}-${seasonIndex}-${index}`, 120);
                            }
                        }
                    })

                    // console.log(totalNoOfPeakMinutesWeekends, "totalNoOfPeakMinutesWeekends Total outsie")

                    // Here checking in total weekends time is not equal to 24 hours
                    if ((totalNoOfPeakMinutesWeekends) !== 1440) {
                        // console.log(totalNoOfPeakMinutesWeekends, "totalNoOfPeakMinutesWeekends inside")
                        errorSeason = {
                            ...errorSeason,
                            weekendsTimeError: 'Weekend should be of 24 Hours'
                        }
                        if (!isError) {
                            isError = true;
                            scrollIntoView(`Total-Time-Weekends-${typeTarrifIndex}-${seasonIndex}`, 120);
                        }
                    }
                }

                let ArrayErrorSesason = Object.entries(errorSeason)
                if (ArrayErrorSesason && ArrayErrorSesason.length) {
                    seasonError[seasonIndex] = errorSeason
                }

            })

            // console.log(totalNoOfSeasonDays, "totalNoOfSeasonDaystotalNoOfSeasonDays")
            let currentYear = moment().format('YYYY');
            let isLeapYear = moment([currentYear]).isLeapYear();
            let totalDaysInYear = isLeapYear ? 365 : 364
            if (totalNoOfSeasonDays !== totalDaysInYear) {
                error = {
                    ...error,
                    seasonDaysError: 'Season should be of 365 Days'
                }
                if (!isError) {
                    isError = true;
                    scrollIntoView(`Season-Days-${typeTarrifIndex}`, 120);
                }
            }

            if (seasonError && seasonError.length) {
                typeError[typeTarrifIndex] = {
                    ...error,
                    seasonError,
                }
            }
            else {
                let ArrayError = Object.entries(error)
                if (ArrayError && ArrayError.length) {
                    typeError[typeTarrifIndex] = {
                        ...error,
                    }
                }
            }
        }

        else {
            let ArrayError = Object.entries(error)
            if (ArrayError && ArrayError.length) {
                typeError[typeTarrifIndex] = {
                    ...error,
                }
            }
        }


    })

    if (typeError && typeError.length) {
        errors = {
            ...errors,
            typeError: typeError,
        }
    }
    else {
        errors = {
            ...errors,
        }
    }


    return {
        isValid: isEmpty(errors),
        errors
    }
}