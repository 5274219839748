import { useEffect, useState, useCallback, useRef } from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Tab,
  Table,
  TableContainer,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import qs from "query-string";
import _ from "lodash";
import moment from "moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import CustomDrawer from "../../../components/common/CustomDrawer";
import CusBackdrop from "../../../components/common/Backdrop";
import * as actions from "./actions";
import SearchFilterButtons from "../../../components/common/SearchFilterButtons";
import CustomAccordion from "../../../components/common/CustomAccordion";
import CustomSearchBar from "../../../components/common/CustomSearchBar";
import { trimFilters } from "../../../utils";
import DateRangePickerRsuit from "../../../components/common/DateRangePicker";
import EnhancedTableHead from "../../../components/common/EnhancedTableHead";
import CustomTablePagination from "../../../components/common/CustomPagination";
import TableLoader from "../../../components/common/TableLoader";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import TableSearch from "../Dashboards/common-comp-hp/TableSearch";
// import SiteTable from "../Dashboards/common-comp-hp/SiteTable";
import HeatPumpTablePagination from "../../../components/common/HeatPumpTablePagination";
import * as socketAction from "../../commonPages/actions";
import { CloseIcon } from "react-material-toast/dist/Icon";
import SiteTable from "../../Admin/Dashboards/common-comp-hp/SiteTable";

const initialFilter = {
  isOpen: false,
  mac_address: "",
  serial_number: "",
  wifi_name: "",
  soft_version: "",
  installed_via: "",
  agency_name: "",
  first_name: "",
  installer_uuid: "",
  device_status: [],
  status: [],
  installation_date: [null, null],
  id: "",
  limit: 25,
  page: 1,
  sortBy: "",
  orderBy: "desc",
  connection_type: [],
  fault_status: "0",
};

const initialAccordion = {
  serialNumberAcc: false,
  modelNumberAcc: false,
  faultCodeAcc: false,
  customerEmailAcc: false,
  deviceWifiStatusAcc: false,
  deviceOperationAcc: false,
  resolvedStatusAcc: false,
};

const manageHead = [
  {
    id: "serial_number",
    sortByKey: "serial_number",
    label: "Fault Devices SNo.",
    className: "show-pointer",
  },
  { id: "mac_address", sortByKey: "mac_address", label: "Model Number", className: "show-pointer" },
  { id: "soft_version", sortByKey: "soft_version", label: "Fault Code", className: "show-pointer" },
  {
    id: "wifi_name",
    sortByKey: "wifi_name",
    label: "Device Wi-Fi Status",
    className: "show-pointer",
  },
  {
    id: "heatpump_device_status",
    sortByKey: "postal_code",
    label: "Device Operation Status",
    className: "show-pointer",
    isMore: true,
  },
  {
    id: "connection_type",
    sortByKey: "connection_type",
    label: "Customer Email",
    className: "show-pointer",
  },
  {
    id: "history_datetime",
    sortByKey: "history_datetime",
    label: "Last Error Record",
    className: "show-pointer",
  },
  {
    id: "installation_date",
    sortByKey: "installation_date",
    label: "Status",
    className: "show-pointer",
  },
  { id: "action", sortByKey: "action", label: "Action", className: "show-pointer" },
];

function FaultySystems(props) {
  const { t = () => {}, toast, classes } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const useRefFaulty = useRef();
  const { allFaultySystem = {}, isLoading = false } = useSelector(
    (state) => state.agencyFaultySystems
  );
  const { data: devices = [], total = 0 } = allFaultySystem;

  const [filteredData, setFilteredData] = useState([]);
  const [newFault, setNewFault] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  const [newFilters, setNewFilters] = useState({ ...initialFilter });
  const [accordion, setAccordian] = useState({ ...initialAccordion });
  const [clearFilterLoader, setClearFilterLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("0");

  const handleTabChange = (e, tab) => {
    setActiveTab(tab);
    setNewFilters({ ...initialFilter, fault_status: tab });
    refreshHeatPumpDevices({ ...trimFilters(newFilters), fault_status: tab, page: 1 });
  };

  const refreshHeatPumpDevices = (filters) => {
    dispatch(actions.getallfaultySystems(filters));
  };

  const goToPageFun = (value) => {
    refreshHeatPumpDevices(value);
  };

  const onClickRefresh = () => {
    setRefresh(true);
    dispatch(actions.getallfaultySystems(newFilters))
      .then(() => setRefresh(false))
      .catch((err) => setRefresh(false));
  };

  const onPageChange = (e, page) => {
    console.log("page", page);
    refreshHeatPumpDevices({ ...newFilters, page });
    setNewFilters({ ...newFilters, page });
  };

  const onChangeLimit = (e) => {
    refreshHeatPumpDevices({
      ...newFilters,
      limit: e.target.value,
      page: 1,
      sortBy: "created_at",
      orderBy: "desc",
    });
    setNewFilters({ ...newFilters, limit: e.target.value, page: 1 });
    setGoToPage("");
  };

  const openFilterSidebar = () => {
    setNewFilters({ ...newFilters, isOpen: true });
  };

  const onCloseFiltersDrawer = () => {
    setNewFilters({ ...newFilters, isOpen: false });
  };

  const handleChangeAccordion = (panel, filterKey) => (event, isExpanded) => {
    if (!isExpanded) {
      if (panel === "installationDateAcc") {
        setNewFilters({ ...newFilters, [filterKey]: [null, null] });
      } else {
        setNewFilters({
          ...newFilters,
          [filterKey]: ["statusAcc", "deviceStatusAcc"].includes(panel) ? [] : "",
          page: newFilters[filterKey]?.length ? 1 : newFilters.page,
        });
      }
    }
    setAccordian({ ...accordion, [panel]: isExpanded });
  };

  const onChangeFilter = ({ target: { name = "", value = "" } }) => {
    if (["mac_address", "serial_number"].includes(name) && value) {
      if (!value.match(/^[a-z0-9]+$/i)) return;
    }
    let tempFilter = { ...newFilters };
    tempFilter = { ...tempFilter, [name]: value, page: 1 };
    setNewFilters(tempFilter);
  };

  const onChangeKeyDown = (e) => {
    if (e.key === "Enter") {
      refreshHeatPumpDevices({ ...trimFilters(newFilters), page: 1 });
    }
  };

  const clearFilter = () => {
    setClearFilterLoader(true);
    let tempFilter = {
      isOpen: newFilters.isOpen,
      mac_address: "",
      connection_type: [],
      device_status: [],
      status: [],
      installation_date: [null, null],
      limit: 25,
      page: 1,
      sortBy: "",
      orderBy: "desc",
      serial_number: "",
      soft_version: "",
      wifi_name: "",
    };

    setNewFilters({ ...tempFilter });
    setAccordian({ ...initialAccordion });
    dispatch(actions.getallfaultySystems(tempFilter)).then((res) => {
      setClearFilterLoader(false);
    });
  };

  const applyFilter = () => {
    refreshHeatPumpDevices({ ...trimFilters(newFilters), page: 1 });
  };

  const alertWarringMsg = () => {
    setNewFault(false);
    onClickRefresh();
  };

  const onChangeGoToPage = ({ target: { value = "" } }) => {
    if (!value.match(/^[0-9]*$/) || value === "0") return;
    let pageValue = Math.ceil(total / newFilters.limit);
    if (value > pageValue) return;
    setGoToPage(value ? parseInt(value) : "");
    setNewFilters((prev) => ({ ...prev, page: value ? parseInt(value) : "" }));
    refreshHeatPumpDevices({ ...newFilters, page: value ? parseInt(value) : "" });
  };

  const resolvedSelected = (data) => {
    dispatch(actions.updatefaultySystem(data))
      .then((res) => {
        refreshHeatPumpDevices([]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!isLoading) {
      setFilteredData(devices);
      useRefFaulty.current = devices;
    } else {
      // setFilteredData([])
    }
  }, [devices]);

  useEffect(() => {
    let tempFilter = { ...initialFilter };
    if (location.search) {
      let {
        serialNumberAcc,
        modelNumberAcc,
        faultCodeAcc,
        deviceWifiStatusAcc,
        deviceOperationAcc,
        customerEmailAcc,
        resolvedStatusAcc,
        agencyNameAcc,
        agency_name,
        deviceStatusAcc,
        device_status = [],
        fullNameAcc,
        first_name,
        id,
        idAcc,
        installationDateAcc,
        installedViaAcc,
        installed_via,
        installer_uuid,
        installer_uuidAcc,
        isOpen,
        macAddressAcc,
        mac_address,
        status = [],
        connection_type = [],
        statusAcc,
        limit,
        page,
        sortBy,
        orderBy,
        serial_number,
        soft_version,
        wifi_name,
        firmwareAcc,
        wifiAcc,
        installation_date = [null, null],
      } = { ...qs.parse(location.search, { arrayFormat: "bracket" }) };

      let isStartDateMoment = moment(installation_date && installation_date[0]).isValid();
      let isEndDateMoment = moment(installation_date && installation_date[1]).isValid();

      tempFilter = {
        first_name,
        id,
        installed_via,
        installer_uuid,
        isOpen: isOpen === "false" ? false : true,
        mac_address,
        status,
        limit: parseInt(limit),
        page: parseInt(page),
        sortBy,
        orderBy,
        serial_number,
        connection_type,
        installation_date: [
          isStartDateMoment ? new Date(installation_date[0]) : null,
          isEndDateMoment ? new Date(installation_date[1]) : null,
        ],
      };

      let tempAcc = {
        serialNumberAcc: serialNumberAcc === "false" ? false : true,
        modelNumberAcc: modelNumberAcc === "false" ? false : true,
        faultCodeAcc: faultCodeAcc === "false" ? false : true,
        deviceWifiStatusAcc: deviceWifiStatusAcc === "false" ? false : true,
        deviceOperationAcc: deviceOperationAcc === "false" ? false : true,
        customerEmailAcc: customerEmailAcc === "false" ? false : true,
        resolvedStatusAcc: resolvedStatusAcc === "false" ? false : true,
      };
      setNewFilters({ ...tempFilter });
      setAccordian({ ...tempAcc });
    }
    refreshHeatPumpDevices(tempFilter);
    return () => {
      // dispatch(actions.clearStoreForDevice())
    };
  }, []);

  useEffect(() => {
    let tempFilter = { ...accordion, ...newFilters };

    let {
      status = [],
      device_status = [],
      installation_date = [null, null],
      connection_type = [],
      ...rest
    } = tempFilter;

    let stringRestFilters = qs.stringify({ ...rest });

    let statusInString = qs.stringify({ status: status }, { arrayFormat: "bracket" });

    let deviceStatusInString = qs.stringify(
      { device_status: device_status },
      { arrayFormat: "bracket" }
    );

    let installationDateInString = qs.stringify(
      { installation_date: installation_date },
      { arrayFormat: "bracket" }
    );

    let connectionTypeInString = qs.stringify(
      { connection_type: connection_type },
      { arrayFormat: "bracket" }
    );

    location.search =
      stringRestFilters +
      "&" +
      statusInString +
      "&" +
      deviceStatusInString +
      "&" +
      installationDateInString +
      "&" +
      connectionTypeInString;
  }, [accordion, newFilters]);

  return (
    <Grid container className="">
      <Grid item xs={12}>
        <Grid container className="location-head">
          <Grid item xs={12} sm={12} lg={7} md={6} className="heading">
            <h2 className="ht-titletext"> Attention [Heat Pump]</h2>
          </Grid>

          <Grid item xs={12} sm={12} lg={5} md={6}>
            <Box className="search-add">
              <IconButton aria-label="filter" onClick={openFilterSidebar}>
                <FilterListIcon />
                <Typography variant="body1" style={{ marginLeft: 8 }}>
                  Search
                </Typography>
              </IconButton>
              <CustomDrawer isOpen={newFilters.isOpen} onClose={onCloseFiltersDrawer}>
                <ul className="list-style-list">
                  <CusBackdrop open={isLoading} className="loader-images" />
                  <li>
                    <CustomAccordion
                      isOpen={accordion.serialNumberAcc}
                      handleChange={handleChangeAccordion("serialNumberAcc", "serial_number")}
                      label={"Fault Devices Number"}>
                      <CustomSearchBar
                        placeholder="Enter Fault Devices Number"
                        value={newFilters.serial_number}
                        onChange={onChangeFilter}
                        name={"serial_number"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>
                  <li>
                    <CustomAccordion
                      isOpen={accordion.modelNumberAcc}
                      handleChange={handleChangeAccordion("modelNumberAcc", "model_name")}
                      label={"Model Name"}>
                      <CustomSearchBar
                        placeholder="Enter Model Name"
                        value={newFilters.model_name}
                        onChange={onChangeFilter}
                        name={"model_name"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>

                  <li>
                    <CustomAccordion
                      isOpen={accordion.faultCodeAcc}
                      handleChange={handleChangeAccordion("faultCodeAcc", "fault_code")}
                      label={"Fault Code"}>
                      <CustomSearchBar
                        placeholder="Enter Fault Code"
                        value={newFilters.fault_code}
                        onChange={onChangeFilter}
                        name={"fault_code"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>

                  <li>
                    <CustomAccordion
                      isOpen={accordion.customerEmailAcc}
                      handleChange={handleChangeAccordion("customerEmailAcc", "email")}
                      label={"Email Address"}>
                      <CustomSearchBar
                        placeholder="Enter Email Address"
                        value={newFilters.email}
                        onChange={onChangeFilter}
                        name={"email"}
                        onChangeKeyDown={onChangeKeyDown}
                      />
                    </CustomAccordion>
                  </li>
                  <li>
                    <SearchFilterButtons
                      clearFilter={clearFilter}
                      applyFilter={applyFilter}
                      isLoading={isLoading}
                      clearFilterLoader={clearFilterLoader}
                    />
                  </li>
                </ul>
              </CustomDrawer>
            </Box>
          </Grid>
        </Grid>
        <Grid container className="alert-msg">
          <Grid item xs={12} sm={12} lg={12} md={12} className="">
            {newFault && (
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="medium"
                    onClick={() => alertWarringMsg()}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{
                  width: "100%",
                }}>
                <AlertTitle>Fault Alert</AlertTitle>
                <p>
                  A new heat pump fault is received.Please{" "}
                  <strong>
                    <button className="alert_btn" onClick={() => alertWarringMsg()}>
                      click here{" "}
                    </button>
                  </strong>{" "}
                  to review it.
                </p>
              </Alert>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="content-container">
        <AppBar position="static" className="bg-white boxshadow-custom tabs-custom-btn">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="tabs"
            variant="fullWidth"
            className=""
            centered>
            <Tab label="UnResolved" value="0" />
            <Tab label="Resolved" value="1" />
          </Tabs>
        </AppBar>
        <div id="" className="card-design table-design">
          {isLoading ? (
            <>
              <TableContainer className={classes?.container} style={{ overflow: "hidden" }}>
                <Table className="table-program" stickyHeader aria-label="sticky table">
                  <EnhancedTableHead headCells={manageHead} filters={newFilters} />
                </Table>
              </TableContainer>
              <TableLoader />
            </>
          ) : null}
          {!isLoading ? (
            <Grid container direction="column">
              <Grid item>
                {!isLoading && (
                  <SiteTable
                    headers={manageHead}
                    resolvedSelected={resolvedSelected}
                    fillters={newFilters}
                    onPageChange={onPageChange}
                    onChangeLimit={onChangeLimit}
                    total={total}
                    data={filteredData}
                    tableType="faultysystems"
                    onClickRefresh={onClickRefresh}
                    refresh={refresh}
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
          <CustomTablePagination
            count={total || filteredData?.length}
            refresh={refresh}
            onClickRefresh={onClickRefresh}
            filters={newFilters}
            onPageChange={onPageChange}
            onChangeLimit={onChangeLimit}
            onChangeGoToPage={onChangeGoToPage}
            goToPage={goToPage}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default FaultySystems;
