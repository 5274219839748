
import { getElecAgentDeivcesHistoryDetailsAPI } from './apis'

export const GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_REQUEST = "GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_REQUEST"
export const GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_SUCCESS = "GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_SUCCESS"
export const GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_FAILED = "GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_FAILED"

export const getElecAgentDeivcesHistoryDetails = id => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_REQUEST"
        })
        getElecAgentDeivcesHistoryDetailsAPI(id)
            .then(res => {
                dispatch({
                    type: "GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_SUCCESS",
                    payload: res.info || {}
                })
                resolve(res.info)
            }).catch(err => {
                dispatch({
                    type: "GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_FAILED",
                })
                reject(err)
            })
    })
}

export const CLEAR_STORE_FOR_COMMON_PAGES = "CLEAR_STORE_FOR_COMMON_PAGES"

export const clearStoreForCommonPages = () => dispatch => {
    return dispatch({
        type: CLEAR_STORE_FOR_COMMON_PAGES
    })
}


// socket connection
export const CONNECT_TO_SERVER="CONNECT_TO_SERVER"
export const DISCONNECT_FROM_SERVER="DISCONNECT_FROM_SERVER"

export const connectToServer = (serverUrl) => ({
    type: 'CONNECT_TO_SERVER',
    payload: serverUrl,
});

export const disconnectFromServer = () => ({
    type: 'DISCONNECT_FROM_SERVER',
});