import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {

    getAllFaultySystemAPI, getFaultyHistoryAPI, getFaultySystemAPIHistoryBySerialNoAPI, getSingleFaultySystemAPI, newFaultAPI, updateFaultAPI
} from './apis'
import { DownloadCSVAllDevicesAPI } from "../../common/apis";

export const GET_FAULTY_SYSTEM_REQUEST = 'GET_FAULTY_SYSTEM_REQUEST';
export const GET_FAULTY_SYSTEM_SUCCESS = 'GET_FAULTY_SYSTEM_SUCCESS';
export const GET_FAULTY_SYSTEM_FAILED = 'GET_FAULTY_SYSTEM_FAILED';

export const getallfaultySystems = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_FAULTY_SYSTEM_REQUEST
        })
        getAllFaultySystemAPI(filters).then(res => {
            dispatch({
                type: GET_FAULTY_SYSTEM_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_FAULTY_SYSTEM_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_SINGLE_FAULTY_SYSTEM_REQUEST = 'GET_SINGLE_FAULTY_SYSTEM_REQUEST';
export const GET_SINGLE_FAULTY_SYSTEM_SUCCESS = 'GET_SINGLE_FAULTY_SYSTEM_SUCCESS';
export const GET_SINGLE_FAULTY_SYSTEM_FAILED = 'GET_SINGLE_FAULTY_SYSTEM_FAILED';

export const getSinglefaultySystem = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SINGLE_FAULTY_SYSTEM_REQUEST
        })
        getSingleFaultySystemAPI(id).then(res => {
            dispatch({
                type: GET_SINGLE_FAULTY_SYSTEM_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SINGLE_FAULTY_SYSTEM_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_FAULTY_SYSTEM_HISTORY_REQUEST = 'GET_FAULTY_SYSTEM_HISTORY_REQUEST';
export const GET_FAULTY_SYSTEM_HISTORY_SUCCESS = 'GET_FAULTY_SYSTEM_HISTORY_SUCCESS';
export const GET_FAULTY_SYSTEM_HISTORY_FAILED = 'GET_FAULTY_SYSTEM_HISTORY_FAILED';

export const getFaultyHistory = (device_id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_FAULTY_SYSTEM_HISTORY_REQUEST
        })
        getFaultyHistoryAPI(device_id).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: GET_FAULTY_SYSTEM_HISTORY_SUCCESS,
                payload: info
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_FAULTY_SYSTEM_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
export const UPDATE_FAULTY_SYSTEM_REQUEST = 'UPDATE_FAULTY_SYSTEM_REQUEST';
export const UPDATE_FAULTY_SYSTEM_SUCCESS = 'UPDATE_FAULTY_SYSTEM_SUCCESS';
export const UPDATE_FAULTY_SYSTEM_FAILED = 'UPDATE_FAULTY_SYSTEM_FAILED';

export const updatefaultySystem = (serialNo) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_FAULTY_SYSTEM_REQUEST
        })
        updateFaultAPI(serialNo).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: UPDATE_FAULTY_SYSTEM_SUCCESS,
                payload: info
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_FAULTY_SYSTEM_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const NEW_FAULTY_SYSTEM_REQUEST = 'NEW_FAULTY_SYSTEM_REQUEST';
export const NEW_FAULTY_SYSTEM_SUCCESS = 'NEW_FAULTY_SYSTEM_SUCCESS';
export const NEW_FAULTY_SYSTEM_FAILED = 'NEW_FAULTY_SYSTEM_FAILED';

export const newfaultySystem = (isUpdate) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: NEW_FAULTY_SYSTEM_REQUEST
        })
        newFaultAPI(isUpdate).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: NEW_FAULTY_SYSTEM_SUCCESS,
                payload: info
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: NEW_FAULTY_SYSTEM_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DownloadCSVAllDevices = () => dispatch => {
    return new Promise((resolve, reject) => {
        DownloadCSVAllDevicesAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}