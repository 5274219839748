import { apiGet, apiPost, apiPut, getSelectedDeviceType, getDeviceType } from "../../../utils";

export function getMultiSiteCompanies(filters, userType) {
  const url = userType == "Admin" ? "/api/nms/msc/get/all" : "/api/agency/nms/msc/get/all";
  let arr = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === "isOpen") continue;
    if (typeof value !== "number" && !value?.length) continue;

    if (["status"].includes(key)) {
      arr.push({ key, value, type: "multi" });
    } else {
      arr.push({ key, value, type: "single" });
    }
  }
  // return apiGet(`/api/nms/msc/get/all?filter=${JSON.stringify(arr)}`)
  return apiGet(`${url}?filter=${JSON.stringify(arr)}`);
}

export function postNewMultiSiteCompany(data, userType) {
  const url = userType === "Admin" ? "/api/nms/msc/add" : "/api/agency/nms/msc/add";
  return apiPost(`${url}`, data);
}

export function updateOldMultiSiteCompany(data, userType) {
  const url =
    userType === "Admin"
      ? `/api/nms/msc/update/${data.id}`
      : `/api/agency/nms/msc/update/${data.id}`;
  return apiPut(`${url}`, data);
}
