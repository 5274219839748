import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    sites: {},
    mscContactList: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_MSC_SITES_REQUEST :
            return { ...state, isLoading: true }

        case actionsTypes.GET_MSC_SITES_SUCCESS:
            return { ...state, isLoading: false, sites: action.payload || {} }

        case actionsTypes.GET_MSC_SITES_FAIED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_MSC_SITES_CONTACT_REQUEST : 
             return {...state }

        case actionsTypes.GET_MSC_SITES_CONTACT_SUCCESS : 
            return {...state, mscContactList: action.payload || {}}

        case actionsTypes.GET_MSC_SITES_CONTACT_FAIED : 
            return {...state }

        case actionsTypes.GET_MSC_SITES_COMPANIES_LIST_REQUEST : 
            return {...state }

       case actionsTypes.GET_MSC_SITES_COMPANIES_LIST_SUCCESS : 
           return {...state, mscCompaniesList: action.payload || {}}

       case actionsTypes.GET_MSC_SITES_COMPANIES_LIST_FAIED : 
           return {...state }

        default:
            return state
    }
}