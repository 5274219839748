import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allDevices: {},
    mssite_list: {},
    isSubmitBtnloading: false,
    isSiteLoading: false,
    SitesDetails: {},
    SitesDevicesIsLoading: false,
    SitesDevicesForTable: {},
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_MULTI_SITE_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_MULTI_SITE_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, allDevices: action.payload || {} }

        case actionsTypes.GET_ALL_MULTI_SITE_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }

        case actionsTypes.GET_MS_SITE_REQUEST:
            return { ...state, isSiteLoading: true }

        case actionsTypes.GET_MS_SITE_SUCCESS:
            return { ...state, isSiteLoading: false, mssite_list: action.payload }

        case actionsTypes.GET_MS_SITE_FAILED:
            return { ...state, isSiteLoading: false }

        case actionsTypes.ASSIGN_ADD_TO_SITE_DEVICES_REQUEST:
            return { ...state, isSubmitBtnloading: true }

        case actionsTypes.ASSIGN_ADD_TO_SITE_DEVICES_SUCCESS:
            return { ...state, isSubmitBtnloading: false }

        case actionsTypes.ASSIGN_ADD_TO_SITE_DEVICES_FAILED:
            return { ...state, isSubmitBtnloading: false }

        case actionsTypes.ADD_NEW_MULTISITE_SITE_REQUEST:
            return { ...state, isSubmitBtnloading: true }

        case actionsTypes.ADD_NEW_MULTISITE_SITE_SUCCESS:
            return { ...state, isSubmitBtnloading: false }

        case actionsTypes.ADD_NEW_MULTISITE_SITE_FAILED:
            return { ...state, isSubmitBtnloading: false }


        case actionsTypes.GET_SITES_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SITES_BY_ID_SUCCESS:
            return { ...state, isLoading: false, SitesDetails: action.payload || {} }

        case actionsTypes.GET_SITES_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SITE_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, SitesDevicesIsLoading: true }

        case actionsTypes.GET_SITE_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, SitesDevicesIsLoading: false, SitesDevicesForTable: action.payload || {} }

        case actionsTypes.GET_SITE_DEVICES_FOR_TABLE_FAILED:
            return { ...state, SitesDevicesIsLoading: false }

        case actionsTypes.CLEAR_SITES_DETAIL:
            return { ...state, ...initialState }

        default:
            return state
    }
}