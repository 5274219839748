import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as commonActions from "../../../containers/common/actions";
import AddOTAGroup from "../../../containers/Admin/OTAGroups/AddOTAGroup";
import Dialog from "./Dialog";
import MultisiteModal from "../../MultisiteModals/MultisiteModal";
import AddMultisite from "../../MultisiteModals/AddMultisite";
import AddSite from "../../SiteModals/AddSite";
import PropertyModal from "../../PropertyModals/PropertyModal";
import AddToSite from "../../AddToSiteModals/AddToSite";
import AddSiteManager from "../../SiteManagerModals/AddSiteManager";
import AddMultisiteContact from "../../MultisiteModals/AddMultiSiteContact";

export default function CustomGlobalDialog() {
  const dispatch = useDispatch();

  const { globalModal } = useSelector((state) => state.common);

  const closeModal = () => {
    dispatch(commonActions.closeGlobalDialog({}));
  };

  switch (globalModal?.modalName) {
    case "ADD_OTA_GROUP":
      return (
        <RenderModalWithComponent
          component={AddOTAGroup}
          customFunction={closeModal}
          maxWidth="sm"
          globalModal={globalModal}
        />
      );
    case "MULTISITE_MODAL":
      return (
        <RenderModalWithComponent
          component={MultisiteModal}
          customFunction={closeModal}
          maxWidth="md"
          globalModal={globalModal}
          className="multisite-modal"
        />
      );
    // case "PROPERTY_MODAL":
    //     return <RenderModalWithComponent component={PropertyModal} customFunction={closeModal} maxWidth="sm" globalModal={globalModal} />
    case "ADD_TO_SITE_MODAL":
      return (
        <RenderModalWithComponent
          component={AddToSite}
          customFunction={closeModal}
          maxWidth="sm"
          globalModal={globalModal}
        />
      );
    case "ADD_MULTISITE_MODAL":
      return (
        <RenderModalWithComponent
          component={AddMultisite}
          customFunction={closeModal}
          maxWidth="sm"
          globalModal={globalModal}
          className="multisite-modal add-multi-company-modal"
        />
      );
    case "ADD_NEW_SITE_MANAGER_MODAL":
      return (
        <RenderModalWithComponent
          component={AddSiteManager}
          customFunction={closeModal}
          maxWidth="sm"
          globalModal={globalModal}
        />
      );
    case "ADD_MULTISITE_CONTACT_MODAL":
      return (
        <RenderModalWithComponent
          component={AddMultisiteContact}
          customFunction={closeModal}
          maxWidth="sm"
          globalModal={globalModal}
        />
      );
    case "ADD_SITE":
      return (
        <RenderModalWithComponent
          component={AddSite}
          customFunction={closeModal}
          maxWidth="sm"
          globalModal={globalModal}
        />
      );
    default:
      return null;
  }
}

const RenderModalWithComponent = ({
  component: Component,
  maxWidth = "sm",
  customFunction,
  className = "",
  ...rest
}) => {
  return (
    <>
      <Dialog fullWidth maxWidth={maxWidth} customFunction={customFunction} className={className}>
        <Component {...rest} closeModal={customFunction} />
      </Dialog>
    </>
  );
};
