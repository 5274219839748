import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allSites: {},
    sitesCount:{},
    singleHeatpumpSites: {},
    deviceHistory: [],
    // graph Data
    onlineofflineGraphdata: { netprofit: { title: "Net Profit", data: [44, 55, 57, 56, 61, 58, 63, 60, 66] }, revenue: { title: "Revenue", data: [76, 85, 101, 98, 87, 105, 91, 114, 94] } },
    faultnonfaultyGraphdata: { faultydevice: { title: "Faulty Device", data: [44, 55, 57, 56, 61, 58, 63, 60, 66] }, nonfaultydevice: { title: "Faulty Device", data: [76, 85, 101, 98, 87, 105, 91, 114, 94] } },
    graphisloading: false,
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_SITES_REQUEST :
            return { ...state, isLoading: true }

        case actionsTypes.GET_SITES_SUCCESS:
            return { ...state, isLoading: false, allSites: action.payload || {} }

        case actionsTypes.GET_SITES_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SITE_COUNT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SITE_COUNT_SUCCESS:
            return { ...state, isLoading: false, sitesCount: action.payload || {} }

        case actionsTypes.GET_SITE_COUNT_FAILED:
            return { ...state, isLoading: false }

        // case actionsTypes.GET_HEAT_PUMP_DEVICE_HISTORY_REQUEST:
        //     return { ...state, isLoading: true }

        // case actionsTypes.GET_HEAT_PUMP_DEVICE_HISTORY_SUCCESS:
        //     return { ...state, isLoading: false, deviceHistory: action.payload || [] }

        // case actionsTypes.GET_HEAT_PUMP_DEVICE_HISTORY_FAILED:
        //     return { ...state, isLoading: false }

        case actionsTypes.GET_GRAPH_DATA:
            return { ...state, graphisloading: true }

        default:
            return state
    }
}