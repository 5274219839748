import { apiGet } from '../../../../../utils';

export function getMscSiteDetail(id) {
    return apiGet(`/api/nms/dashboard/site/${id}/get`, null, 'heat_pump')
}

export function getMscSiteHeatPumpDeviceData(id) {
    return apiGet(`/api/nms/dashboard/site/${id}/ws/devices/all?deviceType=heat_pump`, null, 'heat_pump')
}

export function getMscSiteProperties(id) {
    return apiGet(`/api/nms/dashboard/site/${id}/properties?deviceType=heat_pump&filter=[]`, null, 'heat_pump')
}

export function getMscSiteCounts(id) {
    return apiGet(`/api/nms/dashboard/site/${id}/counts?deviceType=heat_pump`, null, 'heat_pump')
}

